import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter , Link } from 'react-router-dom';
import {user, address,  modalBackdrop, issueEmailMessageConfirmation ,issueEmailConfirmation, modalShow, modalShowUser} from '../../Store/actions';
import  styles from './UserData.module.css';
import axios from '../../axios';
import Gears from '../../Components/UI/Loader/Gears'; 


class UserNameAndAddressForMessage  extends Component {

    state = {
        // name : {
        // first_name : this.props.usrData.first_name,
        // last_name : this.props.usrData.last_name
        // },
        name : '',
        route : '',
        street_number : '',
        locality : '',
        postal_code : '',
        phone : '',
        email : '',
        terms : false,
        newsletter  : false,
        data : false,
        error : false,
        loading : false
    }

    
componentDidMount() {
 

    if (this.props.usrId || localStorage.getItem('userId')) {
        axios.get(`/user/${localStorage.getItem('userId')}`)
        .then(response => {
            console.log(response);
            this.setState({
                name : response.data.first_name+' '+response.data.last_name,
                phone : response.data.phone,
                email : response.data.email
            });
              //get address
                axios.get(`/user/address/${localStorage.getItem('userId')}`)
                .then(response => {
                    this.setState({
                        route : response.data.route,
                        street_number : response.data.street_number,
                        postal_code : response.data.postal_code,
                        locality : response.data.locality,
                        data : true
                    });
                    axios.get(`/shop/${this.props.shpId}`)
                    .then(shop => {
                        this.setState({partner : shop.data.partner._id})
                    })
                    .catch(error => {
                        console.log(error);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        })
        .catch(error => {
            // console.log(error);
        });
    
    } else {

    axios.get(`/shop/${this.props.shpId}`)
    .then(shop => {
        this.setState({partner : shop.data.partner._id})
    })
    .catch(error => {
        console.log(error);
    });
}
    }

    
handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
        [name]: value
    });
    }


  submitHundler = (event) => {
    this.setState({loading : true});
    event.preventDefault();
    if (this.props.usrId || localStorage.getItem('userId')) {
     //ο χρήστης

    let nm=this.state.name;
    let newNm = nm.split(' ')
    const userDataUpdate = {
        first_name : newNm[0],
        last_name :newNm[1],
        phone : this.state.phone
    }
    //η διευθυνση 

    // console.log(userDataUpdate)
    const userAddress = {
        route : this.state.route,
        locality : this.state.locality,
        street_number : this.state.street_number,
        postal_code : this.state.postal_code
    }

    this.props.userAddrData(userAddress);
    this.props.userData(userDataUpdate);
    
    let issue = {
        issue: this.props.issFilter ,
        // user : localStorage.getItem('userId'),
        comment : this.props.cmmntFilter,
        partner : this.state.partner,
        shop : this.props.shpId,
        serviceWay : this.props.serWay
    }

    let device = {
        category : this.props.ctgrFilter ,
        brand : this.props.brndFilter ,
        model : this.props.mdlFilter,
    }

    let messageData = {
        category : this.props.ctgrFilter ,
        brand : this.props.brndFilter ,
        model : this.props.mdlFilter,
        issue: this.props.issFilter ,
        comment : this.props.cmmntFilter,
        route : this.state.route,
        locality : this.state.locality,
        street_number : this.state.street_number,
        postal_code : this.state.postal_code,
        name : this.state.name,
        phone : this.state.phone,
        email : this.state.email,
        serviceWay : this.props.serWay
    }


    // console.log(data)
            axios.post(`/user2/device/${localStorage.getItem('userId')}`, device)
            .then(userDeviceResponse => {
                axios.post(`/user2/issue/${localStorage.getItem('userId')}/${userDeviceResponse.data._id}`, issue)
                .then(userIssueResponse => {
                    axios.post(`/user2/message/${userIssueResponse.data._id}/${this.props.shpId}`, messageData)
                    .then(messageResponse => {
                        this.setState({loading : false});
                        this.props.mHide();
                        this.props.emailConfForMessage(localStorage.getItem('userId'),this.props.shpId,null);
                        this.props.history.replace('/response');
                        
                    })
                    .catch(error => {
                        console.log(error);
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            })
            .catch(error => {
                console.log(error);
            })


    }  else {
    //ο χρήστης
    const user = {
        name : this.state.name,
        phone : this.state.phone,
        email : this.state.email,
        terms : this.state.terms,
        newsletter :this.state.newsletter
    }
    //η διευθυνση 

    // console.log(userDataUpdate)
    const address = {
        route : this.state.route,
        locality : this.state.locality,
        street_number : this.state.street_number,
        postal_code : this.state.postal_code
    }
    
    let issue = {
        issue: this.props.issFilter ,
        // user : localStorage.getItem('userId'),
        comment : this.props.cmmntFilter,
        partner : this.state.partner,
        shop : this.props.shpId,
        serviceWay : this.props.serWay
    }

    let device = {
        category : this.props.ctgrFilter ,
        brand : this.props.brndFilter ,
        model : this.props.mdlFilter,
    }

    let messageData = {
        category : this.props.ctgrFilter ,
        brand : this.props.brndFilter ,
        model : this.props.mdlFilter,
        issue: this.props.issFilter ,
        comment : this.props.cmmntFilter,
        route : this.state.route,
        locality : this.state.locality,
        street_number : this.state.street_number,
        postal_code : this.state.postal_code,
        name : this.state.name,
        phone : this.state.phone,
        email : this.state.email,
        serviceWay : this.props.serWay
    }

    let userId = null;
    let tempPass = null;
    axios.post(`/user2/` , user)
    .then(userResponse => {
        userId =userResponse.data._id;
        tempPass = userResponse.data.password;
        sessionStorage.setItem('userId', userResponse.data._id)
        sessionStorage.setItem('password', userResponse.data.password )
        axios.post(`/user/address/${userId}`, address)
        .then(userAddressResponse => {
            axios.post(`/user2/device/${userId}`, device)
            .then(userDeviceResponse => {
                axios.post(`/user2/issue/${userId}/${userDeviceResponse.data._id}`, issue)
                .then(userIssueResponse => {
                    axios.post(`/user2/message/${userIssueResponse.data._id}/${this.props.shpId}`, messageData)
                    .then(messageResponse => {
                        this.setState({loading : false});
                        this.props.mHide();
                        this.props.emailConfForMessage(userId,this.props.shpId,tempPass);
                        this.props.history.replace('/messageSend');
                        
                    })
                    .catch(error => {
                        console.log(error);
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            })
            .catch(error => {
                console.log(error);
            })
        })
        .catch(error => {
            console.log(error);
        })

    })
    .catch(error => {
        this.setState({loading : false});
        this.setState({error : true})
        console.log(error)
    })
    } 
        
  }    

    
    render() {
    // console.log(this.props)

        let errorMessage = null;
        if (this.state.error) {
        errorMessage =(<div className="alert alert-warning" role="alert" >
                        Έχετε κάνει ήδη εγγραφή με αυτό το email, δοκιμάστε να κάνετε <span onClick={()=>{this.props.mShw('ΣΥΝΔΕΣΗ')}} style={{fontWeight : 'bold'}}>σύνδεση</span></div>);
        setTimeout(() => {errorMessage = (<div></div>)}, 600);
        }


        let element = ( 
        
        <div className="userData">
            { errorMessage }
            <h2>Στοιχεία επικοινωνίας</h2>
            <div className="divider"></div>
            <p>Συμπλήρωσε τα στοιχεία σου και ο τεχνικός θα επικοινωνήσει άμεσα μαζί σου</p>
            <form  onSubmit={this.submitHundler}>
            <div className="form-group">
                <input type='email' className='form-control' id='email' name='email' placeholder='Email' 
                    value={this.state.email} onChange={this.handleInputChange}  required/>
            </div>
            <div className="form-group">
                <input type='text' className='form-control' id='name' name='name' placeholder='Όνοματεπώνυμο' 
                    value={this.state.name} onChange={this.handleInputChange} required />
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <input type='text' className='form-control' id='route' name='route' placeholder='Οδός' 
                        value={this.state.route} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <input type='text' className='form-control' id='street_number' name='street_number' placeholder='Αριθμός' 
                        value={this.state.street_number} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <input type='text' className='form-control' id='locality' name='locality' placeholder='Δήμος' 
                        value={this.state.locality} onChange={this.handleInputChange}/>
                </div>
                <div className="form-group col-6">
                    <input type='text' className='form-control' id='postal_code' name='postal_code' placeholder='Τκ'
                        value={this.state.postal_code} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-group">
                <input type='text' className='form-control' id='phone' name='phone' placeholder='Τηλέφωνο' pattern="[0-9]{10}" maxLength="10" title="1234567890"
                    value={this.state.phone} onChange={this.handleInputChange}  required/>
            </div>
            <div className="form-group">
                <div className={`form-check ${styles.check}`}>
                <input className="" type="checkbox" id="gridCheck1" name="terms" checked={this.state.terms}
            onChange={this.handleInputChange} required/>
                <label className="form-check-label" htmlFor="gridCheck1">
                    Αποδέχομαι τους <Link to="/terms">όρους χρήσης της υπηρεσίας</Link>
                </label>
                </div>
                <div className={`form-check ${styles.check}`}>
                <input className="" type="checkbox" id="gridCheck2" name="newsletter"  checked={this.state.newsletter}
            onChange={this.handleInputChange} />
                <label className="form-check-label" htmlFor="gridCheck2">
                    Επιθυμώ να λαμβάνω νέα και προσφορές.
                </label>
                </div>
            </div>
            <button type="submit" className="btn btn-findfix btn-block user-data-for-message-button" 
            style={{margin : '20px 0', float : 'right'}}>Αποστολή αιτήματος</button>

            </form>
        </div>
        )
 

        if (this.state.loading) {
            element =<Gears />
        }

        return (
            <div>
                { element }
            </div>
            
        )
    }
}

const mapStateToProps = state => {

    return {
        addr : state.searchReducer.address,
        ctgrFilter : state.filterReducer.category,
        brndFilter : state.filterReducer.brand,
        mdlFilter : state.filterReducer.model,
        issFilter : state.filterReducer.issue,
        serWay : state.filterReducer.serviceWay,
        usrId : state.authReducer.userId,
        shpId : state.uiReducer.shopId,
        cmmntFilter : state.filterReducer.comment,
        usrData : state.userReducer.user,
        usrAdrsData : state.userReducer.address
    };
}

const mapDispatchToProps = dispatch => {
    return {
        userData : (data) => dispatch(user(data)),
        userAddrData : (data) => dispatch(address(data)),
        mShwUsr:(value, id, user) => dispatch(modalShowUser(value, id, user)),
        mShw:(value) => dispatch(modalShow(value)),
        mHide : () => dispatch(modalBackdrop()),
        emailConfForMessage : (user, shop, pass) => dispatch(issueEmailMessageConfirmation(user, shop, pass)),
        emailConf : () => dispatch(issueEmailConfirmation()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserNameAndAddressForMessage));

import * as actionTypes from './actionsTypes';
import axios from '../../axios';
import { modalBackdrop } from './index'


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token , userId, message) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId,
        message
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error : error
    };
};

export const logout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userToken');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const auth = (data, isSignup) => {
    return dispatch => {
        dispatch(authStart());
        let url = '/user/signup'
        if (!isSignup) { url = '/user/login'}

        axios.post( url, data )
        .then( response => {
                // localStorage.setItem('userId', response.data.userId);
                // localStorage.setItem('userToken', response.data.token);
                // console.log(response);
                dispatch(authSuccess(response.data.token, response.data.userId, response.data.message)); 
                if (!response.data.message) { 
                    localStorage.setItem('userId', response.data.userId);
                    localStorage.setItem('userToken', response.data.token);
                    dispatch(modalBackdrop());
                }
        } )
        .catch( error => {
            // console.log(error.response);
            dispatch(authFail(error.response.data));
            setTimeout(() => {
                dispatch(authFail(null));
              }, 6000);
        });
    };
};
import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select2 from 'react-select2-wrapper';

import {categoryFilter , brandFilter, issueFilter, modelFilter, commentFilter} from '../../Store/actions';

import './Filters.css';
import 'react-select2-wrapper/css/select2.css';

// import { Category } from '../../Config/Category';
import { Brands } from '../../Config/Brands';
import { Models } from '../../Config/Models';
import { Issues } from '../../Config/Issues';

class Filters extends Component {



  render () {
    // console.log(this.props)

// create brand filter
let brandFilterEllement = null;
let brandFilterData = Brands[this.props.ctgrFilter];
if (brandFilterData) { 
  brandFilterEllement =  
  <div className="form-row">
    <label htmlFor="brand">Μάρκα συσκευής;</label>
    <div className="form-group col-10">
      <Select2
          value ={this.props.brndFilter}
          data={brandFilterData}
          onChange={this.props.onBrandFilter}
          className="form-control modalfilter" 
          id="brand"
          
      />
    </div>
    <div className="form-group col-2">
      <span onClick={()=>{this.props.delBrandFilter(null)}}>
        <ion-icon name="close-circle-outline"></ion-icon>
      </span>
    </div>
  </div>

  }


//create model filter
let modelFilterEllement = null;
let modelFilterData = Models[this.props.brndFilter];
if (modelFilterData) { 
  modelFilterEllement =  
  <div className="form-row">
  <label htmlFor="model">Μοντέλο</label>
  <div className="form-group col-10">
    <Select2
        value ={this.props.mdlFilter}
        data={modelFilterData}
        onChange={this.props.onModelFilter}
        className="form-control modalfilter" 
        id="model"
    /> 
    </div>
    <div className="form-group col-2">
      <span onClick={()=>{this.props.delModelFilter(null)}}>
        <ion-icon name="close-circle-outline"></ion-icon>
      </span>
    </div>
  </div>
}

//create issue filter
let issueFilterEllement = null;
let issueFilterData = Issues[this.props.ctgrFilter];
if ( issueFilterData && this.props.brndFilter !==null) { 
  issueFilterEllement =  
  <div className="form-row">
  <label htmlFor="issue">Πρόβλημα</label>
    <div className="form-group col-10">
        <Select2
          value ={this.props.issFilter}
          data={issueFilterData}
          onChange={this.props.onIssueFilter}
          className="form-control modalfilter" 
          id="issue"
      /> 
      </div>
      <div className="form-group col-2">
        <span onClick={()=>{this.props.delIssueFilter(null)}}>
          <ion-icon name="close-circle-outline"></ion-icon>
        </span>
      </div>
    </div>
  }

//create text area
let textAreaFilterEllement = null;
if ( this.props.issFilter ) {
  textAreaFilterEllement = (
    <Fragment>
      <div className="form-group">
        <label htmlFor="comments">Περιγραφή - Σχόλια</label>
        <textarea className="form-control" id="comments" rows="3"
                  value={this.props.cmmntFilter} onChange={this.props.onCommentFilter}></textarea>
      </div>
    </Fragment>);
}


    return (
      <div>
        <div className="form-row">
        <label htmlFor="category">Τι θέλεις να επισκευάσεις;</label>
          <div className="form-group col-10">
            <Select2
              value ={this.props.ctgrFilter}
              data={this.props.filterCategoryData}
              onChange={this.props.onCategoryFilter}
              className="form-control modalfilter" 
              id="category"
              // options={
              //   {
              //     required: true,
              //   }
              // }
            /> 
          </div>
            <div className="form-group col-2">
              <span onClick={()=>{this.props.delCategoryFilter(null)}}>
                <ion-icon name="close-circle-outline"></ion-icon>
              </span>
            </div>
          </div>
          { brandFilterEllement }
          { modelFilterEllement }
          { issueFilterEllement }
          { textAreaFilterEllement }
        </div>
    );
}}


const mapStateToProps = state => {
  return {
    ctgrFilter : state.filterReducer.category,
    brndFilter : state.filterReducer.brand,
    mdlFilter : state.filterReducer.model,
    issFilter : state.filterReducer.issue,
    cmmntFilter : state.filterReducer.comment,
    show : state.uiReducer.show,
    shpId : state.uiReducer.shopId
  }; 
};

const mapDispatchToProps = dispatch => {

  return {
    onCategoryFilter: (event) => dispatch(categoryFilter(event.target.value)),
    onBrandFilter: (event) => dispatch(brandFilter(event.target.value)),
    onModelFilter: (event) => dispatch(modelFilter(event.target.value)),
    onIssueFilter: (event) => dispatch(issueFilter(event.target.value)),
    onCommentFilter : (event) => dispatch(commentFilter(event.target.value)),
    delCategoryFilter: (val) => dispatch(categoryFilter(val)),
    delBrandFilter: (val) => dispatch(brandFilter(val)),
    delModelFilter: (val) => dispatch(modelFilter(val)),
    delIssueFilter: (val) => dispatch(issueFilter(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

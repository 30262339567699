import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios';
import { modalBackdrop, modalShowUser, favPartnerHandler} from '../../Store/actions';
import styles from './Singleshop.module.css';
import Loader from '../../Components/UI/Loader/Gears';
import SingleShopMap from  '../SingleShopMap/SIngleShopMap';


class Singleshop extends PureComponent {
    state = {
        result : '',
        descriptions :'',
        comments : '',
        loading : true,
    }
    componentDidMount () {
        axios.get('/shop/'+this.props.match.params.shopId)
        .then (response => {
            this.setState({result : response.data});
            // console.log(response.data);
            axios.get('/partner/descriptions/'+response.data.partner._id)
            .then (descriptions => {
                this.setState({descriptions : descriptions.data,
                                loading : false});
                // console.log(descriptions);
            })
            .catch (error => {
                console.log(error);
            });
        })
        .catch (error => {
            console.log(error);
        });

        axios.get(`/shop/comment/${this.props.match.params.shopId}`)
        .then(response => {

            this.setState({'comments':response.data.docs});
        })
        .catch()
        
    }

    phoneButtonhandler =() => {
        var button = document.getElementById("phone");
       
          if (button.getAttribute("data-text-swap") === button.innerHTML) {
            button.innerHTML = button.getAttribute("data-text-original");
          } else {
            button.setAttribute("data-text-original", button.innerHTML);
            button.innerHTML = button.getAttribute("data-text-swap");
          }
        
    }

    render() {
        let color = 'antiquewhite';
        console.log(this.props)
        // console.log(this.state.result.favedBy);
        // // console.log(this.state.result);
        let partner = this.state.result.partner;
        let partnerEmail;
        let comments = 'Δεν υπάρχουν ακόμα κριτικές για το κατάστημα';
        let descriptionServices, descriptionCategory = null;
        let websiteButton = null;
        let serviceButton = <button type="button" 
                                className="btn btn btn-outline-dark" 
                                onClick={(value, id, user) => this.props.mShwUsr('SERVICE', this.props.match.params.shopId, localStorage.getItem('userId'))} 
                                disabled>
                                <span className="flaticon-wrench"></span>
                                Ο τεχνικός είναι εκτός
                            </button>

        //αν εχει site και εχει γινει faved
        if (this.state.result) {
            if (this.state.result.favedBy && this.state.result.favedBy.includes(localStorage.getItem('userId'))) {
                color = 'red'
            }
            websiteButton =  <a href={`https://${this.state.result.website}`}><button type="button" className="btn btn-outline-dark"><span className="flaticon-internet"></span>website</button></a>

        }

        if (this.props.faved) { color = 'red'}


        // console.log(typeof(partner));

        if(partner) {
            partnerEmail = partner.email;
            if(partner.active) {
                serviceButton = <button type="button" 
                                    className="btn btn btn-findfix" 
                                    onClick={(value, id, user) => this.props.mShwUsr('SERVICE', this.props.match.params.shopId, localStorage.getItem('userId'))} 
                                    >
                                    <span className="flaticon-wrench"></span>
                                    Επισκευή
                                </button>
            }
        }

    
        if (this.state.descriptions) {
        //map τροπους εξυπηρετησης σε <P>
        let categoryElement = null;

        descriptionServices = this.state.descriptions.services.map(value => {return (<li key={value}>{value}</li>);});
        descriptionCategory = this.state.descriptions.category.map(value => {
             switch (value) {
                 case 'Laptop':
                    categoryElement = <i className="flaticon-laptop"></i>                     
                     break;
                case 'Tablet':
                    categoryElement = <i className="flaticon-tablet-1"></i>                     
                    break;
                case 'Σταθερός Η/Υ':
                    categoryElement = <i className="flaticon-pc"></i>                     
                    break;
                case 'Κινητό':
                    categoryElement = <i className="flaticon-smartphone"></i>                     
                    break;
                case 'Εκτυπωτής':
                    categoryElement = <i className="flaticon-print"></i>                     
                    break;
                case 'Game Console':
                    categoryElement = <i className="flaticon-console"></i>                     
                    break;
                 default:
                     break;
             }
             return (<p key={value}>{categoryElement}</p>);});
        }

        if (this.state.comments) {
            comments = (this.state.comments).map(data => {
                let pComment = null;
                if (data.partnerComment) {
                    pComment = (<div className={styles.partnerComment}>
                                    <h6>Απάντηση τεχνικού</h6>
                                    {data.partnerComment}
                                </div>);
                }
                return(
                    <div key={data._id}>
                        <div>{data.user.first_name} 
                            <div className={styles.starratingscss}>
                            {/* πάει το data.stars επι το font size */}
                            <div className={styles.starratingscsstop} style={{width : data.stars*13}}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                            <div className={styles.starratingscssbottom}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                            </div>
                        </div>
                    <div>{data.userComment}</div>
                    <div>{pComment}</div>
                    <hr />
                </div> );
            })
        }
        // grid class fro css module
        let grid = 'col-xs-12 col-s-12 col-md-6 col-lg-3 col-xl-3';
        // get logo 
        let img_alt = 'Logo';
        let img_src= '/img/logo_grey.jpg';
        if (this.state.result.logo !== undefined) { 
            img_alt = this.state.result.shop_name;
        //    img_src = `https://boiling-stream-41133.herokuapp.com/${this.state.result.logo}`;
           img_src = `https://api.findfix.gr:4000/${this.state.result.logo}`; 

        }; 


        let element = <Loader />

        if (!this.state.loading) {
            element = 
            (<div className={styles.singleShop}>
                <div className={styles.singleShopMap}>
                    <SingleShopMap markers = {this.state.result.location} current={this.state.result.location} />
                </div>
                <div className="container">
                    <div className="row">
                        <div className={`${grid} ${styles.logo}`}>
                            <img src={img_src} alt={img_alt} />
                        </div>
                        <div className={`${grid} ${styles.info}`}>
                            <div className={styles.title}>
                                <h1 style={{color : '#EF4657', position : 'relative'}}>{this.state.result.shop_name}
                                <span onClick={() => this.props.fvdPartner(this.props.match.params.shopId)} className="flaticon-heart" style={{float : 'none', padding : '0 60px', color : color}}></span></h1>
                            </div>
                            <div><span className="flaticon-shopper"></span><p>{this.state.result.route} {this.state.result.street_number} <br /> {this.state.result.locality}, {this.state.result.postal_code}</p></div>
                            {/* <p><span className="flaticon-call"></span>{this.state.result.phone_number}</p> */}
                            <div><span className="flaticon-mail"></span><p>  {partnerEmail}</p></div>
                        </div>
                        <div className={`${grid} ${styles.details}`}>
                            <h5 style={{fontWeight : 'bold'}}>ΤΡΟΠΟΙ ΕΞΥΠΗΡΕΤΗΣΗΣ</h5>
                            { descriptionServices }
                        </div>
                        <div className={`${grid} ${styles.action}`}>
                            <button type="button" 
                                    id="phone"
                                    className="btn btn-outline-dark" 
                                    onClick={this.phoneButtonhandler}
                                    data-text-swap={this.state.result.phone_number}>
                                    <span className="flaticon-call"></span>
                                    Τηλέφωνο επικοινωνίας
                            </button>
                            { websiteButton }
                            { serviceButton }
                            <button type="button" 
                                    className="btn btn-outline-dark" 
                                    onClick={() => {this.props.history.goBack()}}>
                                    <ion-icon name="arrow-round-back"></ion-icon> Επιστροφή
                            </button>
                        
                        </div>
                    </div>
                        <div className={styles.category}>
                            <h4>Επισκευές :</h4>
                            <div>{descriptionCategory}</div>
                        </div>
                    <div className="clearfix"></div>
                    <div className={styles.comments}>
                        <h4>Αξιολογήσεις πελατών</h4>
                        <div>{ comments }</div>
                    </div>
                    <div className={styles.description}>
                        <h4>Περιγραφή</h4>
                        <div>{this.state.descriptions.details}</div>
                    </div>
                </div>
            </div>);
        }
        return(
            <div style={{minHeight : '80vh'}}>
                {element}
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        shp : state.shopsReducer.shops,
        addr : state.searchReducer.address,
        show : state.uiReducer.show,
        faved : state.generalReducer.fav
    };
};

const mapDispatchToProps = dispatch => {
    
    return {
        mShwUsr:(value, id, user) => dispatch(modalShowUser(value, id, user)),
        fvdPartner : (value) => dispatch(favPartnerHandler(value)),
        mHide:() => dispatch(modalBackdrop())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Singleshop);
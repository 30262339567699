import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select2 from 'react-select2-wrapper';
import axios from '../../axios';

import {categoryFilter , brandFilter, issueFilter, modelFilter, modalBackdrop, modalShowUser, commentFilter} from '../../Store/actions';

import './Filters.css';
import 'react-select2-wrapper/css/select2.css';

import { Category } from '../../Config/Category';
import { Brands } from '../../Config/Brands';
import { Models } from '../../Config/Models';
import { Issues } from '../../Config/Issues';

class Filters extends Component {

state = {
  category : Category
}

componentDidMount () {
 axios.get(`/shop/${this.props.shpId}`)
 .then(result => {
   console.log('sfddddddddddd', result)
   this.setState({category : result.data.description.category});

 })
 .catch(error => {
   console.log('dfssssssssssss',error)
 })
}

  render () {
// create brand filter
let brandFilterEllement = null;
let brandFilterData = Brands[this.props.ctgrFilter];
if (brandFilterData) { 
  brandFilterEllement =  
  <div className="form-group">
      <label htmlFor="brand">Μάρκα συσκευής;</label>
      <Select2
        value ={this.props.brndFilter}
        data={brandFilterData}
        onChange={this.props.onBrandFilter}
        className="form-control modalfilter" 
        id="brand"
    /> 
    </div>
  }

//create model filter
let modelFilterEllement = null;
let modelFilterData = Models[this.props.brndFilter];
if (modelFilterData) { 
  modelFilterEllement =  
  <div className="form-group">
      <label htmlFor="model">Μοντέλο</label>
      <Select2
        value ={this.props.mdlFilter}
        data={modelFilterData}
        onChange={this.props.onModelFilter}
        className="form-control modalfilter" 
        id="model"
    /> 
    </div>
}

//create issue filter
let issueFilterEllement = null;
let issueFilterData = Issues[this.props.ctgrFilter];
if ( issueFilterData && this.props.brndFilter !==null) { 
  issueFilterEllement =  
  <div className="form-group">
      <label htmlFor="issue">Πρόβλημα</label>
      <Select2
        value ={this.props.issFilter}
        data={issueFilterData}
        onChange={this.props.onIssueFilter}
        className="form-control modalfilter" 
        id="issue"
    /> 
    </div>
}

//create text area
let textAreaFilterEllement = null;
if ( this.props.issFilter ) {
  textAreaFilterEllement = (
    <Fragment>
      <div className="form-group">
        <label htmlFor="comments">Περιγραφή - Σχόλια</label>
        <textarea className="form-control" id="comments" rows="3"
                  value={this.props.cmmntFilter} onChange={this.props.onCommentFilter}></textarea>
      </div>
         <button type="button" className="btn btn-findfix" 
         style={{margin : '20px 0', float : 'right'}}
         onClick={(value, id, user) => this.props.mShwUsr('MESSAGEADDRESS', this.props.shpId, localStorage.getItem('userId'))}>
         Επόμενο
      </button>
    </Fragment>);
}

    return (
      <div>
          <h2>Περιγραφή βλάβης</h2>
          <div className="divider"></div>
          <div className="form-group">
            <label htmlFor="category">Τι θέλεις να επισκευάσεις;</label>
            <Select2
              value ={this.props.ctgrFilter}
              data={this.state.category}
              onChange={this.props.onCategoryFilter}
              className="form-control modalfilter" 
              id="category"
        /> 
          </div>
          {brandFilterEllement}
          {modelFilterEllement}
          {issueFilterEllement}
          { textAreaFilterEllement }
        </div>
    );
}}

const mapStateToProps = state => {
  return {
    ctgrFilter : state.filterReducer.category,
    brndFilter : state.filterReducer.brand,
    mdlFilter : state.filterReducer.model,
    issFilter : state.filterReducer.issue,
    cmmntFilter : state.filterReducer.comment,
    show : state.uiReducer.show,
    shpId : state.uiReducer.shopId
  }; 
};

const mapDispatchToProps = dispatch => {

  return {
    onCategoryFilter: (event) => dispatch(categoryFilter(event.target.value)),
    onBrandFilter: (event) => dispatch(brandFilter(event.target.value)),
    onModelFilter: (event) => dispatch(modelFilter(event.target.value)),
    onIssueFilter: (event) => dispatch(issueFilter(event.target.value)),
    onCommentFilter : (event) => dispatch(commentFilter(event.target.value)),
    mShwUsr:(value, id, user) => dispatch(modalShowUser(value, id, user)),
    mHide:() => dispatch(modalBackdrop())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
import React, { Component } from 'react';
import { Switch , Route , withRouter} from 'react-router-dom';
import asyncComponent from '../../Hoc/asyncComponent/asyncComponent';

import Main from '../Main/Main';
import Shops from '../Shops/Shops';
import Singleshop from '../Singleshop/Singleshop';
import Singleshopbyname from '../Singleshop/Singleshopbyname';
import Logout from '../Auth/Logout/Logout';

//Lazy loading of components

const asyncRating = asyncComponent(() => {
    return import('../Rating/Rating')
});

const asyncMessageResponse = asyncComponent(() => {
    return import('../ModalEnquiry/MessageResponse')
});
const asyncResponse = asyncComponent(() => {
    return import('../ModalEnquiry/Response')
});

const asyncForgot = asyncComponent(() => {
    return import('../Forms/Forgot')
});

const asyncEnquirys = asyncComponent(() => {
    return import('../Enquirys/Enquirys')
});


const asyncLogOut = asyncComponent(() => {
    return import('../Forms/Logout')
});

const asyncReset = asyncComponent(() => {
    return import('../Forms/Reset')
});

const asyncEmail = asyncComponent(() => {
    return import('../Forms/Email')
});

const asyncFavs = asyncComponent(() => {
    return import('../Favs/Favs')
});

const asyncTerms = asyncComponent(() => {
    return import('../../Components/Pages/Terms/Terms')
});

const asyncContact = asyncComponent(() => {
    return import('../../Components/Pages/Contact/Contact')
});



class Layout extends Component {
    render () {
        return (
            <Switch>
                <Route path="/" exact component ={ Main } />    
                {/* old routes start */}
                <Route path="/shops/:locality" exact component ={ Shops } />
                <Route path="/:locality/:name"  exact component ={ Singleshopbyname } />
                <Route path="/shops/:locality/:name"  exact component ={ Singleshopbyname } />
                <Route path="/shops/:locality/:route/:name"  exact component ={ Singleshopbyname } />
                {/* old routes finish */}
                
                <Route path="/rating/:shopId"  component ={ asyncRating } />
                <Route path="/response"  component ={ asyncResponse } />
                <Route path="/messageSend"  component ={ asyncMessageResponse } />
                <Route path="/terms"  component ={ asyncTerms } />
                <Route path="/contact"  component ={ asyncContact } />
                <Route path="/enquirys"  component ={ asyncEnquirys } />
                <Route path="/email/"  component ={ asyncEmail } />
                <Route path="/logout" component ={Logout} />  
                <Route path="/forgot"  component ={ asyncForgot } />
                <Route path="/logout"  component ={ asyncLogOut } />
                <Route path="/favs"  component ={ asyncFavs } />
                <Route path="/reset/:token"  component ={ asyncReset } />
                <Route path="/:locality/:category/:brand/:model/:issue" exact component={ Shops } />
                <Route path="/:locality/:route/:name"  exact component ={ Singleshopbyname } />
                <Route path="/:locality" exact component ={ Shops } /> 
            
                {/* old routes start */}
                <Route path="/singleshop/:shopId"  component ={ Singleshop } />
                 {/* old routes finish */}
                <Route path="/partner" component={() => window.location = 'https://partner.findfix.gr'}/>/>
            </Switch>
        )

    }
}
export default withRouter(Layout)
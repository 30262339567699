import React , { Fragment }from 'react'
// import { Link } from 'react-router-dom';
import Logo from '../Nav/NavItems/Logo/Logo';

import MainMenu from '../Nav/NavItems/MainMenu/MainMenu';
// import Address from '../../Containers/Address/Address';

import './Nav.css';

const nav = (props) => {
    // console.log(props);
    let address = null;
    let menu = (
        <Fragment>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <MainMenu  {...props}/>
            </div>
        </Fragment>
    );
    if (localStorage.getItem('userId')) { 
            menu =  (
            <Fragment>
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <img src="../img/user.svg" alt="FindFix" width="30px" />
                </button> */}
                <ul className="ml-auto">
                    <div className="justify-content-end">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/" role="button" aria-haspopup="true" aria-expanded="false">
                        <img src="img/user.svg" alt="FindFix" width="30px" /></a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="/enquirys">Αιτήματα</a>
                                {/* <a className="dropdown-item" href="/favs">Αγαπημένοι</a> */}
                                <a className="dropdown-item" href="/forgot">Αλλαγή κωδικού</a>
                                <a className="dropdown-item" href="/logout">Αποσύνδεση</a>
                            </div>
                    </li>
                
                </div>
                </ul>
            </Fragment>)}
    // if(props.pathname === '/shops' || props.pathname === '/shops/address') { address = <Address />}

    return (
        <div>
            {/* <div className="container"> */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Logo />
                {/* <div className="partnerSignup">
                <a href="https://partner.findfix.gr" style={{textDecoration : 'none'}}><button type="button" className="btn btn-outline-findfix btn-block">Εγγραφή Τεχνικού</button></a>
                </div> */}
                { menu }
            </nav>
         {/* </div> */}
            <div className="nav-address">
                { address }            
            </div>
        </div>
    );
}

export default nav;
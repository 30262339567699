import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  fav : false,
  userId : '',
  shopId : ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FAVORITE_PARTNER:
            return {
                ...state,
                fav : true
                     }
        case actionTypes.UN_FAVORITE_PARTNER:
            return {
                ...state,
                fav : false
                    }
        default :
            return state;
    }
}

export default reducer;
import * as actionsTypes from './actionsTypes';

export const placeToGeo =(value) => {
    // console.log(value)
    let google = window.google;
    return (dispatch) => {
    let location = null;
    var geocoder =  new google.maps.Geocoder();
    geocoder.geocode( { 'address': value , region : 'gr'}, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            location= { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()} ;
            // console.log(location)
                dispatch(geometryChange(location));
          } else {
            alert("Something got wrong " + status);
          }
          
        });
    }
}


export const geometryChange =(value) => {
    // console.log(value);
    return {
        type : actionsTypes.GEOMETRY_CHANGE,
        value : value
    };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withGoogleMap, GoogleMap , Marker, OverlayView} from 'react-google-maps';

import './Map.css';
// import shop from '../../Components/Shop/Shop';
const { compose, withStateHandlers } = require("recompose");
class Map extends Component {
   render() {

    // console.log(this.props)
    let positions; 
    let markers;

    let current = { lat: 38.031200, lng: 23.706840}
    if (this.props.geo) { current =  this.props.geo }
    if (this.props.markers) {
    positions = this.props.markers.map(data=> {
      // console.log(data);
      return data.location;
    });

  
    markers = positions.map(data => {
      return ({lng : data[0], lat: data[1]});
    })
  }


  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })
  
   const GoogleMapExample  = compose(
    withStateHandlers(() => ({
      isOpen: false,
    }), {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      })
    }),withGoogleMap)(props => (
  
      <GoogleMap
        defaultCenter = { current }
        defaultZoom = { 12 }
      >

     <OverlayView
      position={ current }
      /*
       * An alternative to specifying position is specifying bounds.
       * bounds can either be an instance of google.maps.LatLngBounds
       * or an object in the following format:
       * bounds={{
       *    ne: { lat: 62.400471, lng: -150.005608 },
       *    sw: { lat: 62.281819, lng: -150.287132 }
       * }}
       */
      /*
       * 1. Specify the pane the OverlayView will be rendered to. For
       *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
       *    Defaults to `OverlayView.OVERLAY_LAYER`.
       */
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      /*
       * 2. Tweak the OverlayView's pixel position. In this case, we're
       *    centering the content.
       */
      getPixelPositionOffset={getPixelPositionOffset}
      /*
       * 3. Create OverlayView content using standard React components.
       */
    >
      <span className="beacon"></span>
   
    </OverlayView>
      {props.markers && 
          props.markers.map(marker => {
            // console.log(marker)
            return(
            <Marker
              key={marker.lat}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={props.onToggleOpen}
            >
           {/* {props.isOpen && <InfoWindow key={marker.lat} onCloseClick={props.onToggleOpen}>
           <div>{positions}</div></InfoWindow>} */}
            </Marker>
          )})
        }

      </GoogleMap>
   ));
   return(
    <div>
        <GoogleMapExample
          containerElement={ <div style={{ height: `73vh`, width: '100%', display : 'flex' }} /> }
          mapElement={ <div style={{ height: `100%` , width : `100%`}} /> }
          markers = {markers}
          current = {current}
        />
      
    </div>
   );
   }
};

const mapStateToProps = state => {

  return {
      geo : state.searchReducer.geometry
  };
};

const mapDispatchToProps = dispatch => {

  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

import axios from '../../axios';
import * as actionsTypes from './actionsTypes';
import { placeToGeo } from './geometry';

export const fetchShops =(list) => {
    // console.log('ayto einai to fetc' + list);
    return {
        type : actionsTypes.FETCH_SHOPS,
        list : list
        };
}

export const filterShops =(list) => {
    // console.log('ayto einai to fetc' + list);
    return {
        type : actionsTypes.FILTER_SHOPS,
        list : list
        };
}


export const deleteShops =() => {
    return {
        type : actionsTypes.DELETE_SHOPS,
        };
}




export const errorHundling = (message) => {
    return {
        type : actionsTypes.ERROR_HUNDLING,
        message : message
        };
    }

export const listShops = (locality) => {
    // console.log(locality);
    sessionStorage.setItem('locality', locality);
    return (dispatch) =>{
            axios.get('/search/locality/'+ locality + '/10')
            .then(result => {
                // console.log(result.data);
                // sessionStorage.setItem('shops',  JSON.stringify(result.data));
                dispatch(fetchShops(result.data));
                dispatch(placeToGeo(locality));
            })
            .catch(error => {
                // console.log(error.message);
                dispatch(errorHundling(error.message));
            })
        }
    }


export const descriptionShops = (value, shops) => {
    let newShops = [];  //νεο array
    for(let i=0; i<shops.length; i++) {
        if((shops[i].description.category).find(element => element===value)) {
            //για καθε αντικείμενο που έχει μέσα το value το προσθέτω στο array
            newShops.push(shops[i]);
        }
    }
    return (dispatch) => {
        sessionStorage.setItem('newShops',  JSON.stringify(newShops));
        dispatch(filterShops(newShops));
    }

}
export const IN_CHANGE= 'IN_CHANGE'; 

export const FETCH_SHOPS = 'FETCH_SHOPS';
export const FILTER_SHOPS = 'FILTER_SHOPS';
export const DELETE_SHOPS = 'DELETE_SHOPS';


export const LOADER = 'LOADER';
export const ERROR_HUNDLING = 'ERROR_HUNDLING';

//user data action types
export const USER_DATA = 'USER_DATA';
export const USER_ADDRESS = 'USER_ADDRESS';


//auth action types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


//ui action types 
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_BACKDROP = 'MODAL_BACKDROP';


//filters 
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const MODEL_FILTER = 'MODEL_FILTER';
export const ISSUE_FILTER = 'ISSUE_FILTER';
export const COMMENT_FILTER = 'COMMENT_FILTER';
export const SEARCH_FILTER = 'SEARCH_FILTER';
export const DESCRIPTION_SERVICE_FILTER = 'DESCRIPTION_SERVICE_FILTER';

//general 
export const ISSUE_RESPONSE_EMAIL = 'ISSUE_RESPONSE_EMAIL';
export const FAVORITE_PARTNER = 'FAVORITE_PARTNER';
export const UN_FAVORITE_PARTNER = 'UN_FAVORITE_PARTNER';


//geometry 

export const GEOMETRY_CHANGE ='GEOMETRY_CHANGE';



import React from 'react'
import styles from './MenuItems.module.css';

const menuitems = (props) => {
    return (
        <li className={styles.navItem}>
            <button type="button" className={`btn btn-link ${styles.navLink}`} onClick={props.clicked}>{props.name}</button>
        </li>
    );
}

export default menuitems;
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import axios from '../../axios';
import { modalBackdrop, modalShowUser, favPartnerHandler } from '../../Store/actions';
import styles from './SingleshopByName.module.css';
import Loader from '../../Components/UI/Loader/Gears';
import SingleShopMap from  '../SingleShopMap/SIngleShopMap';
import Filters from '../Filters/FiltersInShop';
import ServicesFilter from '../Filters/ServicesFilters';




class Singleshop extends PureComponent {
    state = {
        result : '',
        descriptions :'',
        comments : '',
        loading : true,
        error : false,
        filters : false
    }
    componentDidMount () {
        window.scrollTo(0,0);

        axios.get(`/shop/route/${this.props.match.params.locality}/${this.props.match.params.route}/${this.props.match.params.name}`)
        .then (response => {
  
            // για το παλιο του route που ηταν περιοχη/ονομα 
            if(response.data.length < 1) {
         
                axios.get(`/shop/name/${this.props.match.params.locality}/${this.props.match.params.name}`)
                .then (response => {
       
                    this.setState({result : response.data[0]});
                    axios.get('/partner/descriptions/'+response.data[0].partner._id)
                    .then (descriptions => {
                        this.setState({descriptions : descriptions.data,
                                        loading : false});
                        // console.log(descriptions);
                        axios.get(`/shop/comment/${response.data[0]._id}`)
                        .then(comments => {
                            // console.log(comments.data);
                            this.setState({'comments':comments.data.docs});
                        })
                        .catch()
                    })
                    .catch (error => {
                        console.log(error);
                    });
                })
                .catch (error => {
                    console.log(error);
                });
            } else {

                //ξαναστελνει να παρει το σωστο - μαλακια εχω κανει εδω
        axios.get(`/shop/route/${this.props.match.params.locality}/${this.props.match.params.route}/${this.props.match.params.name}`)
        .then (response => {
            this.setState({result : response.data[0]});
            axios.get('/partner/descriptions/'+response.data[0].partner._id)
            .then (descriptions => {
                this.setState({descriptions : descriptions.data,
                                loading : false});
                // console.log(descriptions);
                axios.get(`/shop/comment/${response.data[0]._id}`)
                .then(comments => {
                    // console.log(comments.data);
                    this.setState({'comments':comments.data.docs});
                })
                .catch()
            })
            .catch (error => {
                console.log(error);
            });
        })
        .catch (error => {
            console.log(error);
        });
    }
});

        ReactGA.pageview(window.location.pathname + window.location.search);

        
    }

    swapButtonhandler =(e) => {
        var button = document.getElementById(e.target.name);
       
          if (button.getAttribute("data-text-swap") === button.innerHTML) {
            button.innerHTML = button.getAttribute("data-text-original");
          } else {
            button.setAttribute("data-text-original", button.innerHTML);
            button.innerHTML = button.getAttribute("data-text-swap");
          }
        
    }


    chechFilterHandler = () => {
        // if (this.props.ctgrFilter === '' || this.props.srvcway === '') {
        //         this.setState({filters : true})
        // } else {
        //    this.props.mShwUsr('MESSAGEADDRESS', this.state.result._id, localStorage.getItem('userId'))
        // }

        this.props.mShwUsr('MESSAGEADDRESS', this.state.result._id, localStorage.getItem('userId'))

    }
    render() {
        
         // eslint-disable-next-line
        let color = 'antiquewhite';
        let discount = null;
        if(this.state.result.discount) {
            discount =( <div className={styles.discount}>
            <h4>Προσφορά</h4>
                <div>Στείλε αίτημα μέσα από το FindFix και κέρδισε έκπτωση έως {this.state.result.discount}% στο κόστος της επισκευής</div>
            </div>);
        }
        let comments = null;
        let descriptionServices, descriptionCategory, descriptionServicesFilter = null;
        let websiteButton = null;
        let serviceButton = <button type="button" 
                                className="btn btn btn-findfix message-button" 
                                onClick={this.chechFilterHandler} 
                                >
                                <span className="flaticon-mail"></span>
                                Αίτημα επισκευής
                            </button>

        let serviceButtonForPhone = <button type="button" 
                            className="btn btn-findfix btn-block message-button" 
                            onClick={(value, id, user) => this.props.mShwUsr('SERVICE', this.state.result._id, localStorage.getItem('userId'))} 
                            >
                            <span className="flaticon-wrench"></span>
                                Αίτημα επισκευής
                            </button>
        //αν εχει site και εχει γινει faved
        if (this.state.result) {
            if (this.state.result.favedBy && this.state.result.favedBy.includes(localStorage.getItem('userId'))) {
                color = 'red'
            }
            if(this.state.result.website !== '') {
                websiteButton = (
                    <button type="button" 
                        id="site"
                        name = "site"
                        className="btn btn-outline-findfix" 
                        onClick={this.swapButtonhandler}
                        data-text-swap={this.state.result.website}>
                        <span className="flaticon-internet"></span>
                        website
                    </button>
                ); 
            }
        }

    //     if (this.props.faved) { color = 'red'}


    //     if(this.state.result.active) {
    //         if(this.props.iss !== null) {
    //         serviceButton = (<button type="button" 
    //                             className="btn btn btn-findfix live-message-button" 
    //                             onClick={(value, id, user) => this.props.mShwUsr('SERVICE', this.state.result._id, localStorage.getItem('userId'))} 
    //                             >
    //                             <span className="flaticon-wrench"></span>
    //                             Επισκευή
    //                         </button>)
    //     } else {
    //         serviceButton = <button type="button" 
    //         className="btn btn btn-findfix live-message-button" 
    //         onClick={(value, id, user) => this.props.mShwUsr('LIVESERVICE', this.state.result._id, localStorage.getItem('userId'))} 
    //         >
    //         <span className="flaticon-wrench"></span>
    //         Επισκευή
    //     </button>
    //     }
    // }
    
        if (this.state.descriptions) {
        //map τροπους εξυπηρετησης σε <P>
        let categoryElement = null;

        descriptionServices = this.state.descriptions.services.map(value => {return (<li key={value}>{value}</li>);});
        descriptionServicesFilter = <ServicesFilter descriptionServicesData={this.state.descriptions.services} />
        descriptionCategory = this.state.descriptions.category.map(value => {
             switch (value) {
                 case 'Laptop':
                    categoryElement =<span>Laptop <i className="flaticon-laptop"></i></span>                      
                     break;
                case 'Tablet':
                    categoryElement = <span>Tablet  <i className="flaticon-tablet-1"></i></span>                     
                    break;
                case 'Σταθερός Η/Υ':
                    categoryElement = <span>Σταθερούς Η/Υ  <i className="flaticon-pc"></i></span>                     
                    break;
                case 'Κινητό':
                    categoryElement = <span>Κινητά  <i className="flaticon-smartphone"></i></span>                     
                    break;
                case 'Εκτυπωτής':
                    categoryElement = <span>Εκτυπωτές  <i className="flaticon-print"></i></span>                     
                    break;
                case 'Game Console':
                    categoryElement = <span>Παιχνιδομηχανές<i className="flaticon-console"></i></span>                     
                    break;
                 default:
                     break;
             }
             return (<p key={value}>{categoryElement}</p>);});
        }
        if (this.state.comments !== '' && this.state.comments.length > 0) {
            comments =( 
                    <div className={styles.comments}>
                        <h4>Αξιολογήσεις πελατών</h4>
                            {(this.state.comments).map(data => {
                            let pComment = null;
                            if (data.partnerComment) {
                                pComment = (<div className={styles.partnerComment}>
                                                <h6>Απάντηση τεχνικού</h6>
                                                {data.partnerComment}
                                            </div>);
                            }
                            if (data.confirmed) {
                                return(
                                   
                                        <div key={data._id}>
                                        <div>{data.user.first_name} 
                                            <div className={styles.starratingscss}>
                                            {/* πάει το data.stars επι το font size */}
                                            <div className={styles.starratingscsstop} style={{width : data.stars*13}}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                                            <div className={styles.starratingscssbottom}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                                            </div>
                                        </div>
                                    <div>{data.userComment}</div>
                                    <div>{pComment}</div>
                                 <hr />
                            </div> );
                            } else {
                                return null;
                            }
                        })
                    } 
            </div>
            );
        }

        // get logo 
        let img_alt = 'Logo';
        let img_src= '/img/logo_grey.jpg';
        if (this.state.result.logo !== undefined) { 
            img_alt = this.state.result.shop_name;
        //    img_src = `https://boiling-stream-41133.herokuapp.com/${this.state.result.logo}`;
           img_src = `https://api.findfix.gr:4000/${this.state.result.logo}`; 

        }; 

        //
        let fillFilters = null;
        if(this.state.filters) {
            fillFilters = <p style={{color : 'red'}}>Παρακαλώ συμπληρώστε τα φίλτρα</p>
            setTimeout(() => {
                this.setState({filters : false})
            }, 6000);
        }
        let element = <Loader />

        if (!this.state.loading) {
            element = 
            (<div className={styles.singleShop}>
                <Helmet>
                    <title>{`${this.state.result.shop_name} στην περιοχή ${this.state.result.locality} | FindFix`}</title>
                    <meta name="description" content={`${this.state.result.shop_name} στην περιοχή ${this.state.result.locality}`} />
                </Helmet>
                <div className={styles.singleShopMap}>
                    <SingleShopMap markers = {this.state.result.location} current={this.state.result.location} />
                </div>
                <div className="container">
                    <div className={`row`}>
                        <div className={`col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ${styles.logo}`}>
                            <img src={img_src} alt={img_alt} />
                        </div>
                        <div className={`col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 ${styles.info}`}>
                        <div className={styles.title}>
                            <h1 style={{color : '#EF4657', position : 'relative'}}>{this.state.result.shop_name}
                                {/* <span onClick={() => this.props.fvdPartner(this.state.result._id)} className="flaticon-heart" style={{float : 'none', padding : '0 60px', color : color}}></span>*/}
                            </h1> 
                            </div>
                            <div>
                                <div className={styles.infoDetails}>    
                                    <p><strong><span className="flaticon-shopper"></span>Διεύθυνση</strong></p>
                                    <p>{this.state.result.route} {this.state.result.street_number}, {this.state.result.locality}, {this.state.result.postal_code}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`col-xs-12 col-sm-12 col-md-12 offset-lg-2 col-lg-4 offset-xl-2 col-xl-4`}>
                        <div className={styles.action}>

                        <p><strong>Στείλε αίτημα επισκευής για πιο γρήγορη εξυπηρέτηση.</strong></p>
                        { fillFilters }
                        <hr />
                        <div className="accordion" id="accordionExample">
                            <div>
                                <div id="headingOne">
                                    <button className={styles.showMoreButton} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className="flaticon-wrench"></span> 
                                    Ποιο είναι το πρόβλημα
                                    <ion-icon name="arrow-dropdown"></ion-icon>
                                    </button>
                                </div>
                                <hr />
                                <div id="collapseOne" className="collapse hide" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                    <Filters filterCategoryData = {this.state.descriptions.category}/>                           
                                    </div>
                                </div>
                                <div id="headingTwo">
                                    <button className={styles.showMoreButton} type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <span className="flaticon-help"></span> 
                                    Πως θέλεις να εξυπηρετηθείς
                                    <ion-icon name="arrow-dropdown"></ion-icon>
                                    </button>
                                </div>
                                <hr />
                                <div id="collapseTwo" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                   { descriptionServicesFilter }                         
                                    </div>
                                </div>
                            </div>
                         
                            
                        </div>
                        { serviceButton }
                        </div>
                       
                        </div>
                    </div>


                    <div className={styles.actionPhone}>
                        { serviceButtonForPhone }
                    </div>
                    {/* blogs */}
                    <div className={styles.description}>
                        <h4>Περιγραφή</h4>
                        <div>{this.state.descriptions.details}</div>
                    </div>
                    { discount }
                    <div className={styles.category}>
                        <h4>Τι επισκευάζουμε</h4>
                        <div>{descriptionCategory}</div>
                        <hr />
                        <h4>Τρόποι εξυπηρετησης</h4>
                        <div>{descriptionServices}</div>
                    </div>
                    <div className={styles.description}>
                        <h4>Στοιχεία Τεχνικού</h4>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className={styles.infoDetails}>    
                                    <p><strong><span className="flaticon-shopper"></span>Διεύθυνση</strong></p>
                                    <p>{this.state.result.route} {this.state.result.street_number}, {this.state.result.locality}, {this.state.result.postal_code}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <button type="button" 
                                    id="phone"
                                    name = "phone"
                                    className="btn btn-outline-findfix" 
                                    onClick={this.swapButtonhandler}
                                    data-text-swap={this.state.result.phone_number}>
                                    <span className="flaticon-call"></span>
                                    Τηλέφωνο επικοινωνίας
                                </button>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                { websiteButton }
                            </div>
                        </div>
                                
                    </div>
                   { comments }

                </div>
            </div>);
        }

        if (this.state.error) { element = <div>Κάτι πήγε λάθος</div>}
        return(
            <div style={{minHeight : '80vh'}}>
                {element}
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        shp : state.shopsReducer.shops,
        addr : state.searchReducer.address,
        show : state.uiReducer.show,
        faved : state.generalReducer.fav,
        iss : state.filterReducer.issue,
        ctgrFilter : state.filterReducer.category,
        srvcway : state.filterReducer.serviceWay
    };
};

const mapDispatchToProps = dispatch => {
    
    return {
        mShwUsr:(value, id, user) => dispatch(modalShowUser(value, id, user)),
        fvdPartner : (value) => dispatch(favPartnerHandler(value)),
        mHide:() => dispatch(modalBackdrop())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Singleshop);
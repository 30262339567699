import axios from 'axios';

// const instance = axios.create({
//     baseURL: 'http://localhost:4000/'
// });

// const instance = axios.create({
//     baseURL: 'https://boiling-stream-41133.herokuapp.com' 
// });

const instance = axios.create({
    baseURL: 'https://api.findfix.gr:4000' 
});


export default instance;
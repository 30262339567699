import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider,  } from 'react-redux';
import { ConnectedRouter, connectRouter, routerMiddleware  } from 'connected-react-router'
import { Route } from 'react-router-dom';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import App from './App';

import searchReducer from './Store/reducers/searchReducer';
import shopsReducer from './Store/reducers/shopsReducer';
import authReducer from './Store/reducers/authReducer';
import uiReducer from './Store/reducers/uiReducer';
import filterReducer from './Store/reducers/filterReducer';
import userReducer from './Store/reducers/userReducer';
import generalReducer from './Store/reducers/generalReducer';

import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line
const google = window.google;

const rootReducer= combineReducers(
      { uiReducer : uiReducer, 
        shopsReducer : shopsReducer , 
        searchReducer : searchReducer, 
        authReducer : authReducer,
        filterReducer : filterReducer,  
        userReducer : userReducer,
        generalReducer : generalReducer
      });

const store = createStore(
  connectRouter(history)(rootReducer), // new root reducer with router state
  composeEnhancers(compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk
    ),
  ),
));

ReactGA.initialize('UA-132180389-1');

ReactDOM.render(
  
 
    <Provider store={store}>
         <ConnectedRouter history={history}>
            <Route path="/" component={ App } />
        </ConnectedRouter>
    </Provider>, document.getElementById('root'));
registerServiceWorker();

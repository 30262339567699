import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    token : null,
    userId : null,
    message : null,
    error : null,
    loading : null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                error : null,
                loading : true
                     }
        case actionTypes.AUTH_SUCCESS:
        return {
            ...state,
            error : null,
            loading : false,
            token : action.token,
            userId : action.userId,
            message : action.message
                }
        case actionTypes.AUTH_FAIL:
        return {
            ...state,
            error : action.error,
            loading : false
                    }
        case actionTypes.AUTH_LOGOUT:
        return {
            ...state,
            error : null,
            loading : false,
            token : null,
            userId :null,
            message : null
                }
        default :
            return state;
    }
}

export default reducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import {deleteShops} from '../../Store/actions';
import Search from '../Search/Search';
import How from '../../Components/Marketing/How';
import Partner from '../../Components/Marketing/Partner';
import Popular from '../../Components/Marketing/Popular';
 // eslint-disable-next-line
import Locality from '../../Components/Marketing/Locality';
import './Main.css';

  class Main extends Component {

    componentDidMount () {
     this.props.onDeleteShops();
    }
  
    render() {
      // console.log(this.props)
      return (
        <div>
          <Helmet>
            <title>Βρες τον τεχνικό σου | FindFix</title>
            <meta name="description" content="Η No1 ολοκληρωμένη πλατφόρμα αναζήτησης Τεχνικών για επισκευή ηλεκτρονικών υπολογιστών, laptop, κινητών, printer, game console σε όλη την Ελλάδα" />
          </Helmet>
          <section id="header">

              <div className="text-wrapper">
              Βρες τεχνικό για 
                <div className="animated-words">
                    <span>τον υπολογιστή σου</span>
                    <span>το κινητό σου</span>
                    <span>το laptop σου</span>
                    <span>το tablet σου</span>
                    <span>τον εκτυπωτή σου</span>
                    <span>την κονσόλα σου</span>
                </div>    
  
          </div>
            <div className="container"> 
              <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12 col-xs-12">
                <Search />
                <h3>Περιγράφεις το πρόβλημα,<br />επιλέγεις τεχνικό,<br />κανονίζεις επισκευή</h3>
              </div>
            </div>
          </section>
          {/* <Locality /> */}
          <Popular/>
          <How />
          <Partner />
        </div>
     
        )
    }
}


const mapDispatchToProps = dispatch => {

  return {
    onDeleteShops: (val) => dispatch(deleteShops(val)),
  };
};

export default connect(null, mapDispatchToProps)(Main);

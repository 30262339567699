import React, { Component } from 'react';
import { connect } from 'react-redux';
// import axios from '../../axios';
import { withRouter } from 'react-router-dom'

import { mainChange, findLoc } from '../../Store/actions';
import styles from './Search.module.css';

class Search extends Component {


   state = { 
       loading : false
   }

   onClickHandler = (e) => 
   {
        e.preventDefault()
       this.setState({loading : true})
       this.props.onFindLocation();
   }

   handleSubmit =(e) => {
    e.preventDefault();

    this.props.history.push(`/${this.props.addr.locality}`)
   }


   onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
}
    render() {

        let buttonElement = (   <span className={`auto-search-button ${styles.locateButton}`} 
                                onClick={this.onClickHandler}
                                title="Αυτόματη αναζήτηση τοποθεσίας">
                                    <div className={styles.locate}>
                                        <div className={styles.locateI}>
                                            <ion-icon name="locate"></ion-icon>
                                        </div>
                                    </div>
                                 </span>)


        if(this.state.loading) { buttonElement= (<span className={styles.locateButton}>
                                    <div className={styles.locate}>
                                        <div className={styles.locateI}>
                                            <i className="fa fa-circle-o-notch fa-spin"></i>
                                        </div>
                                    </div>
                                </span>)}
        //σβήνω ότι έχει αποθηκέυσει
        sessionStorage.clear();
        
        return(
                <div className="search">
                <form className="main_search_form" onKeyPress={this.onKeyPress} onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <div className={`col-md-9 ${styles.search}`}>
                            <div className="row">
                                <div className="col-10">
                                    <input type="text" id="address" 
                                    className={`form-control form-control-lg ${styles.searchInput}`} 
                                    onChange={this.props.onMainChange} 
                                    placeholder="Βάλε την περιοχή σου..." 
                                    required/>
                                </div>
                                <div className="col-2">
                                    {buttonElement}
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-3 ${styles.button}`}>
                            <button type="submit" className="btn btn-findfix btn-lg main-search-button">Αναζήτηση</button>
                        </div>
                    </div>
                    </form>
                </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        addr : state.searchReducer.address,
    };
};

const mapDispatchToProps = dispatch => {

    return {
        onMainChange: (event) => {
            event.preventDefault()
            dispatch(mainChange(event.target.value));
        },
        onFindLocation : () => dispatch(findLoc())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
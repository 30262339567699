import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    show : false,
    modalInput : '',
    shopId : ''
    }

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.MODAL_SHOW:
            sessionStorage.setItem("shopId", action.id);
            return {
                ...state,
               show : true,
               modalInput : action.value,
               shopId : action.id
                     }
        case actionTypes.MODAL_BACKDROP:
        return {
            ...state,
            show : false,
            modalInput : ''
                }
        default :
            return state;
    }
}

export default reducer;
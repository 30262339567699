import * as actionTypes from './actionsTypes';

export const modalShow = (value, id) => {
    return {
        type: actionTypes.MODAL_SHOW,
        value : value,
        id : id,
    };
};

// αυτο βαζουμε αν θελουμε πρωτα συνδεση
//  export const modalShowUser = (value, id, user) => {
//     // console.log(user);
//     return (dispatch) =>{ 
//         if (user) {dispatch(modalShow(value, id)) 
//         } else { dispatch(modalShow('ΣΥΝΔΕΣΗ', null))}     
//     }

// }

export const modalShowUser = (value, id, user) => {
        // console.log(user);
        return (dispatch) =>{ dispatch(modalShow(value, id))}
     }

export const modalBackdrop = () => {
    return {
        type: actionTypes.MODAL_BACKDROP,
    };
};




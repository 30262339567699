import { descriptionShops } from './index';
import * as actionsTypes from './actionsTypes';

export const categoryFilter =(value) => {
    sessionStorage.setItem('categoryFilter', value);
    return {
        type : actionsTypes.CATEGORY_FILTER,
        value :value
    };
}


export const categoryFilterToShops = (value, shops) => {
    // console.log(shops);
    return (dispatch) =>{ 
        dispatch(categoryFilter(value));
        dispatch(descriptionShops(value,shops));
    }
};

export const brandFilter =(value) => {
    sessionStorage.setItem('brandFilter', value);
    return {
        type : actionsTypes.BRAND_FILTER,
        value : value
    };
}

export const modelFilter =(value) => {
    sessionStorage.setItem('modelFilter', value);
    return {
        type : actionsTypes.MODEL_FILTER,
        value :value
    };
}

export const issueFilter =(value) => {
    sessionStorage.setItem('issueFilter', value);
    return {
        type : actionsTypes.ISSUE_FILTER,
        value : value
    };
}


export const commentFilter =(value) => {
    sessionStorage.setItem('commentFilter', value);
    return {
        type : actionsTypes.COMMENT_FILTER,
        value : value
    };
}


export const searchResult=(value) => {
    return {
        type : actionsTypes.SEARCH_FILTER,
        value : value
    };
}


export const descriptionServices=(value) => {
    return {
        type : actionsTypes.DESCRIPTION_SERVICE_FILTER,
        value : value
    };
}

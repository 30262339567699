import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Modal from '../../Components/UI/Modal/Modal';
import Nav from '../../Components/Nav/Nav';


import AuthSignup from '../Auth/AuthSignup';
import AuthLogin from '../Auth/AuthLogin';
import Filters from '../Filters/ModalFilters';
// import Address from '../ModalEnquiry/UserDataForLive'
import MessageAddress from '../ModalEnquiry/UserDataForMessage';
import Response from '../ModalEnquiry/Response';
import ServiceWay from '../ModalEnquiry/ServiceWay';

import './Header.css';

import * as actions from '../../Store/actions/index';
// import axios from '../../axios';


ReactGA.initialize('UA-132180389-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class Header extends Component {

    onModalShow =(value) => {
    this.props.show ? this.props.mHide() : this.props.mShow(value)
    }

    render() {
        let modalHtml = null;
        // isSignup ? auth = <AuthSignup /> : auth =<AuthLogin />
        switch (this.props.mdlInputText) {
            case 'ΣΥΝΔΕΣΗ':
            modalHtml =<AuthLogin />
                break;
            case 'ΕΓΓΡΑΦΗ':
            modalHtml =<AuthSignup />
                break;
            case 'LIVESERVICE':
            modalHtml = <Filters />
                break;
            case 'SERVICE':
            modalHtml = <Filters />
                break;
            case 'MESSAGEADDRESS':
            modalHtml = <MessageAddress />
                break;
            case 'SERVICEWAY':
            modalHtml = <ServiceWay />
                    break;
            case 'RESPONSE':
            modalHtml = <Response />
                break;
            default:
                break;
        }
      return (
        <div className="header">
            <Modal show={this.props.show} backdropClose={this.onModalShow} inputText={this.props.mdlInputText}>
                {modalHtml}
            </Modal>
            <Nav locality={this.props.addr.locality}  modalShow={this.onModalShow} pathname = {this.props.location.pathname}/>
        </div>
        
        )
    }
  }

    const mapStateToProps = state => {

      return {
          addr : state.searchReducer.address,
          show : state.uiReducer.show,
          mdlInputText : state.uiReducer.modalInput,
          userToken : state.authReducer.token,
          user : state.authReducer.userId
      };
  };
  
  const mapDispatchToProps = dispatch => {
  
      return {
        mShow:(value) => dispatch(actions.modalShow(value)),
        mHide:() => dispatch(actions.modalBackdrop())

        
      };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Header);
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Header from './Containers/Header/Header';
import Layout from './Containers/Layout/Layout';
import Footer from './Containers/Footer/Footer';

import './App.css';

class App extends Component {
  render() {
    return (

        <div>
          <Helmet>
          < meta name="description" content="Η No1 ολοκληρωμένη πλατφόρμα αναζήτησης Τεχνικών για επισκευή ηλεκτρονικών υπολογιστών, laptop, κινητών, printer, game console σε όλη την Ελλάδα" />
          </Helmet>
          <Header {...this.props} />
          <Layout {...this.props} />
          <Footer {...this.props}/>
        </div>
    );
  }
}

export default App;

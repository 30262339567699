
export const Issues = {
  'Laptop':['Αλλαγή Τροφοδοτικού','Βλάβη από νερό','Αλλαγή οθόνης','Αλλαγή μπαταρίας','Εγκατάσταση λειτουργικού','Εγκατάσταση προγραμμάτων','Αλλαγή πληκτρολογίου','Επισκευή κάρτας γραφικών','Επισκευή μητρικής','Καθαρισμός από Ιούς','Format','Επισκευή Βύσματος','Γενικός έλεγχος','Αλλαγή πάστας','Αλλαγή ανεμιστήρα','Αναβάθμιση','Αλλαγή σκληρού δίσκου','Back up','Επιδιόρθωση Σφαλμάτων','Δεν ανοίγει','Δεν συνδέεται στο internet','Δεν ακούγεται ήχος','Κλείνει ξαφνικά','Άλλο'], 
   'Κινητό'
  :['Σπασμένη οθόνη αφής','Σπασμένη οθόνη LCD','Βλάβη από νερό','Αλλαγή μπαταρίας','Επισκευή μητρικής','Θύρα φόρτισης','Κουμπί εκκίνησης','Ακουστικό','Μικρόφωνο','Ηχείο','Κάλυμμα μπαταρίας','Επαναφορά λογισμικού','Back up', 'Μεταφορά δεδομένων','Δεν ανοίγει','Δεν συνδέεται στο internet','Δεν έχει σήμα','Δεν ακούγεται ήχος','Άλλο'],
  'Tablet'
  :['Σπασμένη οθόνη αφής','Σπασμένη οθόνη LCD','Βλάβη από νερό','Αλλαγή μπαταρίας','Επισκευή μητρικής','Θύρα φόρτισης','Κουμπί εκκίνησης','Ακουστικό','Μικρόφωνο','Ηχείο','Κάλυμμα μπαταρίας','Επαναφορά λογισμικού','Back up', 'Μεταφορά δεδομένων','Δεν ανοίγει','Δεν συνδέεται στο internet','Δεν έχει σήμα','Δεν ακούγεται ήχος','Άλλο'],
   'Σταθερός Η/Υ'
  :['Αλλαγή Τροφοδοτικού','Εγκατάσταση λειτουργικού','Εγκατάσταση προγραμμάτων','Επισκευή μητρικής','Καθαρισμός από Ιούς','Format','Επισκευή Βύσματος','Γενικός έλεγχος','Αλλαγή πάστας','Αλλαγή ανεμιστήρα','Αναβάθμιση','Αλλαγή σκληρού δίσκου','Back up','Επιδιόρθωση Σφαλμάτων','Αλλαγή Κάρτας Γραφικών','Επισκευή Κάρτας Γραφικών','Αλλαγή Επεξεργαστή','Δεν ανοίγει','Δεν συνδέεται στο internet','Δεν ακούγεται ήχος','Κλείνει ξαφνικά','Άλλο'],
  'Εκτυπωτής'
  :['Δεν τυπώνει','Τυπώνει λευκές σελίδες','Τυπώνει λερωμένες σελίδες','Κολλάει το χαρτί','Η εκτύπωση είναι θολή','Άλλο'],
   'Game Console'
  :['Δεν ανοίγει','Δεν δουλεύει το χειριστήριο','Δεν συνδέεται το χειριστήριο','Δεν συνδέεται στο internet','Κλείνει ξαφνικά','Δεν φορτώνει παιχνίδια','Άλλο'],
  };
  
  
import { push } from 'connected-react-router'
import * as actionsTypes from './actionsTypes';
import { listShops } from './shops';


let url=null;
export const inputChange =(val) => {
    // localStorage.setItem('locality', val.locality);

    return {
        type : actionsTypes.IN_CHANGE,
        value : val
    };
}


export const mainChange =(val) => {
    return (dispatch) =>{

      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_3: 'short_name',
        country: 'long_name',
        postal_code: 'short_name', 
        formatted_address: '',
        geometry : '',
      };


        const google = window.google;
        let place;
        let input = document.getElementById('address');
        let options = {
            componentRestrictions: {
            country: 'gr'
                }
            };
    
            let autocomplete = new google.maps.places.Autocomplete(input, options);
    
            google.maps.event.addListener(autocomplete, 'place_changed',  () => {
    
            place = autocomplete.getPlace();  
                if (!place.address_components) {
                    return false
                } else {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                // console.log(addressType);
                
                componentForm[addressType]= place.address_components[i][componentForm[addressType]];
                // console.log(componentForm);
              }
            componentForm.formatted_address = place.formatted_address;
            componentForm.geometry = place.geometry;
            sessionStorage.setItem('locality',componentForm.locality);
            // dispatch(push(`/shops/${componentForm.locality}`));
            dispatch(inputChange(componentForm));
           
            // dispatch(listShops(componentForm.locality));
            
            }})    
          
            
    }
   
}

export const refreshChange =(val) => {
    // console.log(val);
    return (dispatch) =>{
        dispatch(push('/shops'));
        dispatch(inputChange(val));
        dispatch(listShops(val));
    }
}


export const inChange =(val) => {
    // console.log(val);
    return (dispatch) =>{

      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_3: 'short_name',
        country: 'long_name',
        postal_code: 'short_name', 
        formatted_address: '',
      };


        const google = window.google;
        let place;
        let input = document.getElementById('address');
        let options = {
            componentRestrictions: {
            country: 'gr'
                }
            };
    
            let autocomplete = new google.maps.places.Autocomplete(input, options);
    
            google.maps.event.addListener(autocomplete, 'place_changed',  () => {
    

            place = autocomplete.getPlace();  
            if (!place.address_components) {
                return false
            } else {
            
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                componentForm[addressType]= place.address_components[i][componentForm[addressType]];
                // console.log(componentForm[addressType]);
              }
            componentForm.formatted_address = place.formatted_address;
            componentForm.geometry = place.geometry;


            // dispatch(push('/shops'));
            dispatch(inputChange(componentForm));
            dispatch(push(`/shops/${componentForm.locality}`));
            // dispatch(listShops(componentForm.locality));
            
            }})    
          
            
    }
   
}

//FINDS GEOMETRY FROM CITY AND STORES IT

    // let google = window.google;
    // let location = null;
    // var geocoder =  new google.maps.Geocoder();
    // geocoder.geocode( { 'address': value }, function(results, status) {
    //       if (status === google.maps.GeocoderStatus.OK) {
    //         location= { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()} ;
    //         return (dispatch) => {
    //             dispatch(geometryChange(location));
    //         }
    //       } else {
    //         alert("Something got wrong " + status);
    //       }
    //     });







export const findGeocode = (latlng) => {
    // console.log(latlng);
    return(dispatch) => {
        var componentForm = {
            locality: 'long_name',
            formatted_address: '',
            geometry : ''
          };
        const google = window.google;
        var geocoder = new google.maps.Geocoder();
        
        geocoder.geocode({'location': latlng}, function(results) {

            componentForm.locality = results[0].address_components[2].long_name;
            componentForm.formatted_address = results[0].formatted_address;
            componentForm.geometry = results[0].geometry;

         if(url !== null) {
            dispatch(push(`/${componentForm.locality}/${url}`));
            dispatch(inputChange(componentForm));
         } else {
            //  dispatch(push('/shops'));
            dispatch(push(`/${componentForm.locality}`));
             dispatch(inputChange(componentForm));
            //  dispatch(listShops(componentForm.locality));
         }

        });
    }
}

export const findLoc =() => {

    return (dispatch) =>{

        navigator.geolocation.getCurrentPosition((position) =>{
            var latitude  = position.coords.latitude;
            var longitude = position.coords.longitude;
            // console.log(latitude ,  longitude);
            let latlng ={ lat : latitude, lng : longitude};
            dispatch(findGeocode(latlng));
          })
        
    }   
}


export const popularButton =(val) => {
    return(dispatch) =>{
        url = val ;
        dispatch(findLoc());
  }
    
}


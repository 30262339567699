import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
// import queryString from 'query-string';
import { listShops , modalBackdrop, modalShowUser, unFavPartner, categoryFilterToShops, brandFilter, modelFilter, issueFilter} from '../../Store/actions';
import { Link } from 'react-router-dom';
import './Shops.css';
import Shop from '../../Components/ShopNew/Shop';
import Topbar from '../../Components/Topbar/Topbar';
import Map from '../Map/Map';
import Loader from '../../Components/UI/Loader/Gears';



class Shops extends Component {
    state = { 
        noresult : false,
        shopId : '',
        loading : sessionStorage.getItem('shopsLoading')
    }

    
    componentDidMount () {

       if(this.props.match.params.locality === 'undefined' || this.props.match.params.locality === 'long_name') {
           this.props.history.push('/')
       } else {
        this.props.getShopList(this.props.match.params.locality);
        this.props.unFvPartner();    

        if (this.props.shp === null) {
            setTimeout(() => {
            this.setState({noresult : true})
              }, 800);
        }
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    }


    render() {
        // console.log(this.props);

        //filters from popular
        let categoryFilter = null
        switch (this.props.match.params.category) {
            case 'laptop': 
            categoryFilter = 'Laptop' 
                break;
            case 'desktop': 
            categoryFilter = 'Σταθερός Η/Υ' 
                break;
            case 'phone': 
            categoryFilter = 'Κινητό' 
                break;
            case 'tablet': 
            categoryFilter = 'Tablet' 
                break;
            case 'printer': 
            categoryFilter = 'Εκτυπωτής'
                break;
            case 'console': 
            categoryFilter =  'Game Console'
                break;
            default:
                break;
        }
        let fullElement = <Loader />;
        let map = null;
        let element= null;

        //if error1
        if (this.props.err) {
            element = <div>σφαλμα { this.props.errMessage }</div>
        }
           //παιρνουμε το shp απο το session - palia
        let shp =this.props.shp;
        // console.log(shp);

        if(!this.props.newShp) {
        if (shp && shp !== '' && this.props.geo ) {

            //τα φίλτρα απο το url
            if (this.props.match.params.category) {
                this.props.onCategoryToShop(categoryFilter, shp)
                if (this.props.match.params.brand && this.props.match.params.brand!== 'brand') {
                    this.props.onBrandFilter(this.props.match.params.brand)
                    }
                if (this.props.match.params.model && this.props.match.params.model!== 'model') {
                    this.props.onModelFilter(this.props.match.params.model)
                    }
                if (this.props.match.params.issue && this.props.match.params.issue!== 'issue') {
                    this.props.onIssueFilter(this.props.match.params.issue)
                    }
            }

            map =   <Map markers={shp} current={this.props.addr.geometry} shopInfo = { shp } />

            element = (
            <div>

                <p style={{marginLeft: '10px'}}>Βρέθηκαν {(shp.length)} αποτελέσματα</p>
                <div className="row">
                {Object.values(shp).map((data) => {
                    // console.log(this.props.geo.lat)
                    var arr = data.stars;
                    var total_comments = arr.length;
                    var subtotal = 0;
                    var total=0;
                    for(var i in arr) { subtotal += arr[i]; }
                    total = subtotal/arr.length;
                    return( 
                        <div key={data._id} className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <Shop 
                                    name={data.shop_name} 
                                    route={data.route} 
                                    street_number={data.street_number} 
                                    locality={data.locality} 
                                    origin={[this.props.geo.lng, this.props.geo.lat]} 
                                    destination={data.location}
                                    id = {data._id}
                                    logo = {data.logo}
                                    onModal={this.props.mShwUsr}
                                    stars ={total}
                                    total = {total_comments}
                                    active = {data.active}
                                    discount = {data.discount}
                                    userId = {localStorage.getItem('userId')}/>
                        </div>

                            );
                        })}
                </div>
            </div>
            );

                }
            } else { 

                let newShp =JSON.parse(sessionStorage.getItem('newShops'));
                map =   <Map markers={newShp} current={this.props.geo} shopInfo = { newShp } />
                element = (
                <div >
                    <p style={{marginLeft: '10px'}}>Βρέθηκαν {(newShp.length)} αποτελέσματα</p>
                    <div className="row">
                    {Object.values(newShp).map((data) => {
                         var arr = data.stars;
                          var total_comments = arr.length;
                          var subtotal = 0;
                          var total=0;
                          for(var i in arr) { subtotal += arr[i]; }
                          total = subtotal/arr.length;
                        return( 
                                <div key={data._id} className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <Shop 
                                        name={data.shop_name} 
                                        route={data.route} 
                                        street_number={data.street_number} 
                                        locality={data.locality} 
                                        origin= {[this.props.geo.lng, this.props.geo.lat]} 
                                        destination={data.location}
                                        id = {data._id}
                                        logo = {data.logo}
                                        stars ={total}
                                        total = {total_comments}
                                        active = {data.active}
                                        discount = {data.discount}
                                        onModal={this.props.mShwUsr}
                                        userId = {localStorage.getItem('userId')}/>
                            </div>
    
                                );
                            })}
                    </div>
                </div>
                );
            }

        //if no result
        if (this.props.shp === '' && this.state.noresult) {

            element =(
                <div style={{textAlign : 'center'}}>
                    <h3>Δεν βρέθηκαν αποτελέσματα στην περιοχής σας.<br />Δείτε σε άλλη περιοχή</h3>
                    <Link to='/'>Νέα αναζήτηση</Link>
                </div>
         )}
        
         if(this.state.loading === 'true' || this.props.ldng)
         {
             if(this.props.geo) {
             fullElement = (  
             <div className="row shops"> 
             <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 map">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <strong>Περιοχή : </strong>{sessionStorage.getItem('locality')}
                        </div>
                        <div className="col-6">
                             <select className="custom-select">
                                <option value="grapefruit">Grapefruit</option>
                                <option value="lime">Lime</option>
                                <option value="coconut">Coconut</option>
                                <option value="mango">Mango</option>
                            </select> 
                        </div>
                    </div>
                </div>  */}
                <div className="results">
            
                    { element }
                </div>
             </div>
             <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 map">
                 { map }
             </div>
         </div>)
         }
        }
// 

        return(
            <div style={{minHeight : '80vh'}}>
                <Helmet>
                    <title>Τεχνικοί Η/Υ και Κινητών {this.props.match.params.locality} | FindFix</title>
                    <meta name="description" content={`Δες όλους τους τεχνικούς στην περιοχή ${this.props.match.params.locality}, και στείλε αίτημα επισκευής άμεσα`} />
                </Helmet>
                {/* {this.props.addr} */}
               {/* { serviceModal} */}
                <Topbar  />                  
                  { fullElement }
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        addr : state.searchReducer.address,
        shp : state.shopsReducer.shops,
        newShp : state.shopsReducer.newShops,
        err : state.shopsReducer.error,
        errMessage :state.shopsReducer.errorMessage,
        show : state.uiReducer.show,
        usrId : state.authReducer.userId,
        ldng : state.shopsReducer.loading,
        geo : state.searchReducer.geometry
    };
};

const mapDispatchToProps = dispatch => {

    return {
        unFvPartner : () => dispatch(unFavPartner()),
        getShopList : (data) => dispatch(listShops(data)), 
        mShwUsr:(value, id, user) => dispatch(modalShowUser(value, id, user)),
        mHide:() => dispatch(modalBackdrop()),
        onCategoryToShop : (val , shop) => dispatch(categoryFilterToShops(val, shop)),
        onBrandFilter: (val) => dispatch(brandFilter(val)),
        onModelFilter: (val) => dispatch(modelFilter(val)),
        onIssueFilter: (val) => dispatch(issueFilter(val)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shops);
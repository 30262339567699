import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import axios from '../../axios';
import styles from './Footer.module.css';

  class Footer extends Component {
    state = {
      email : ''
    }

    handleChange = (event) => {
      this.setState({email: event.target.value});
    }
  
    newsletterHandler =  async (e) =>{
      
      e.preventDefault();
      let data = {
        email : this.state.email,

    }
    try {
        const response = await axios.post('/index/newsletter', data);
        alert(response.data.message);
    } catch (error) {
        alert(error.response.data.message);
    }
    }

    render() {
      return (
        <div className={styles.footer}>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Εταιρια</h5>
                <a href="/"><li>Σχετικά</li></a>
                <a href="/contact"><li>Επικοινωνία</li></a>
                <a href="https://blog.findfix.gr/"><li>Blog</li></a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Ιστοσελιδα</h5>
                <a href="/terms"><li>Όροι χρήσης</li></a>
                <a href="/terms"><li>Πολιτική απορρήτου</li></a>
                <a href="/"><li>Χαρτης Ιστότοπου</li></a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Υποστηριξη</h5>
                <a href="/"><li>Για τεχνικούς</li></a>
                <a href="/"><li>Για χρήστες</li></a>
                <a href="/"><li>Πως λειτουργεί</li></a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <h5>Newletter</h5>
                <p style={{marginBottom : '5px'}}>Κάνε εγγραφή και μάθε πρώτος νεα και προσφορές</p>
                <form onSubmit={this.newsletterHandler}>
                  <div className="input-group mb-3">
                    <input type="email" 
                      className="form-control" 
                      value={this.state.email} onChange={this.handleChange}
                      placeholder="Email" aria-label="Email" aria-describedby="newsletter" 
                      required/>
                    <div className="input-group-append">
                      <button className="btn btn-findfix" 
                        type="submit" id="newsletter"
                        >Εγγραφή</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        <div className="row">
          <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ${styles.footerLogo}`}>
            <img src="/img/logo-white.svg" alt="FindFix" width="100px" />
          </div>
            <div className={`col-xl-3 offset-xl-6 col-lg-3 offset-lg-6 col-md-6 col-sm-12 col-xs-12 ${styles.socialIcons}`}>
              <p>Ακολούθησέ μας :</p>
              <a href="https://www.facebook.com/FindFix.gr"><ion-icon ios="logo-facebook" md="logo-facebook"></ion-icon></a>
              <a href="https://www.twitter.com/FindFix.gr"><ion-icon ios="logo-twitter" md="logo-twitter"></ion-icon></a>
              <a href="https://www.instagram.com/FindFix.gr"><ion-icon ios="logo-instagram" md="logo-instagram"></ion-icon></a>
            </div>
          </div>
        </div>
          <p style={{textAlign : 'center'}}>2019 © made with ♥ by FindFix Team</p>
        </div>
        
        )
    }
  }
export default Footer;
import React from 'react';
import './Partner.css';

const partner = () => {
    return (
    <div className="partner">
        <div className="container">
        <h3>Βάλε την επιχείρηση σου στον χάρτη</h3>
        <div className="divider"></div>
            <div className="row">
               
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 " style = {{padding :'20px'}}>
                    <p style={{fontSize:'14px', lineHeight :'20px', padding : '20px 0'}}><strong>Μια πλατφόρμα για τεχνικούς Η/Υ και κινητών.</strong><br />Εκατοντάδες πελάτες αναζητούν συνεχώς την επιχείρηση σου. <br />Μέσα από το findfix μπορούν άμεσα να έρθουν σε επαφή μαζί σου, και να επισκευάσουν την συσκευή τους γρήγορα και εγγυημένα.<br />Κάνε σήμερα μια <strong>ΔΩΡΕΑΝ</strong> εγγραφή και εκμεταλλεύσου της δυνατότητες του findfix </p>
                    <a href="https://partner.findfix.gr" style={{textDecoration : 'none'}}><button type="button" className="btn btn-outline-findfix btn-block">Εγγραφή Τεχνικού</button></a>
                </div>
            </div>
        </div>
    </div>
    )
}

export default partner;
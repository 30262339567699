import React, { Component }from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Filters from '../../Containers/Filters/Filters';
import './Topbar.css';


class Topbar extends Component {
render() {
    return (
        <div className="topbar">
            <h2>Τεχνικοί κοντά στην περιοχή {this.props.match.params.locality}</h2>
            <h6>Επέλεξε από τα αποτελέσματα και στείλε αίτημα επισκευής</h6>
	        <p>Ανακάλυψε τεχνικούς στην περιοχή σου και μάθε περισσότερα για αυτούς! Δες κριτικές και στείλε αίτημα επισκευής</p>
            <p>Χρησιμοποίησε τα φίλτρα και περιόρισε τα αποτελέσματα </p>
            <Filters />
        </div>
    );
}

}

const mapStateToProps = state => {

    return {
        noOfShops : state.shopsReducer.numberOfShops,
    };
};

export default connect(mapStateToProps, null)(withRouter(Topbar));
import React from 'react'
import MenuItems  from '../MenuItems/MenuItems';

const mainmenu = (props) => {
    return (
            <ul className="navbar-nav"> 
                {/* <MenuItems name={ <a href="https://partner.findfix.gr" style={{textDecoration : 'none'}}> Εγγραφή Τεχνικού </a>} /> */}
                <MenuItems name='ΣΥΝΔΕΣΗ' clicked={() => props.modalShow('ΣΥΝΔΕΣΗ')} />
                <MenuItems name='ΕΓΓΡΑΦΗ' clicked={() => props.modalShow('ΕΓΓΡΑΦΗ')} />
            </ul>
    );
}

export default mainmenu;
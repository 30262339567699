import React from 'react';
import './Gears.css';

const gears = ( props ) => { 
    return ( 
    <div className="gears">
        <img src="/img/big-gear.png" alt="gear" className="big" />
        <img src="/img/small-gear.png" alt="gear" className="small" />
    </div>
    );
}

export default gears;
import * as actionTypes from './actionsTypes';
import axios from '../../axios';



export const userData = (data) => {
    // console.log({data});
    return {
        type: actionTypes.USER_DATA,
        data
    };
};

export const userAddress = (data) => {
    // console.log({data});
    return {
        type: actionTypes.USER_ADDRESS,
        data
    };
};


export const user = (data) => {
    // console.log({data});
    return dispatch => {       
    axios.get(`/user/${localStorage.getItem('userId')}`)
    .then(response => {
        // console.log('αναβαθμιζουμε τον χρηστη onoma', response.data);
        axios.patch(`/user/${localStorage.getItem('userId')}`, data)
        .then( response => {
        dispatch(userData(response.data));
        })
        .catch( error => {
            console.log(error.response);
        });
    })
    .catch( error => {
        // console.log('δεν υπαρχει ο χρηστης',error.response);
        axios.post(`/user/${localStorage.getItem('userId')}`, data)
        .then( response => {
            dispatch(userData(response.data));
        })
        .catch( error => {
            console.log(error.response);
        });
    });
    };
};

export const address = (data) => {
    // console.log({data});
    return dispatch => {
        axios.get(`/user/address/${localStorage.getItem('userId')}`)
        .then(response => {
            // console.log('αναβαθμιζουμε τον χρηστη', response)
            axios.patch(`/user/address/${localStorage.getItem('userId')}`, data)
            .then( response => {
            console.log(response)
            dispatch(userAddress(response.data));
            })
            .catch( error => {
                console.log(error.response);
            });
        })
        .catch( error => {
            console.log('δεν υπαρχει ο χρηστης',error.response);
            axios.post(`/user/address/${localStorage.getItem('userId')}`, data)
            .then( response => {
                dispatch(userAddress(response.data));
            })
            .catch( error => {
                console.log(error.response);
            });
        });
    };
};
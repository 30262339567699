import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    shops :null,
    newShops : null,
    numberOfShops : null,
    error : false,
    errorMessage : null,
    loading : false
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_SHOPS:
            sessionStorage.setItem('shopsLoading', true);
            return {
                ...state,
                shops : action.list,
                numberOfShops : action.list.length,
                error : false,
                loading : true
            }
        case actionTypes.DELETE_SHOPS:
        sessionStorage.setItem('shopsLoading', false);
        return {
            ...state,
            shops :null,
            newShops : null,
            numberOfShops : null,
            error : false,
            loading : false
        }
        case actionTypes.FILTER_SHOPS:
            return {
                ...state,
                newShops : action.list,
                numberOfShops : action.list.length,
                error : false,
                loading : true
            }
        case actionTypes.ERROR_HUNDLING:
        return {
            ...state,
            error : true,
            loading : true,
            errorMessage : action.message
        }
        default :
            return state;
    }
}

export default reducer;
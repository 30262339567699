import React from 'react';
import { Link } from 'react-router-dom'
import './Shop.css'

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    // console.log(lat1, lat2, lon1, lon2, dLat, dLon);
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d =  Math.round((R * c) * 100) / 100; // Distance in km
    return d;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

const shop = (props) => {
    // console.log(props);
    // console.log(props.origin[0], props.origin[1], props.destination[0], props.destination[1]);
    // let openings_hours = null;
    // if (!props.openings_hours) {
    //    openings_hours = <p>Κλειστό</p>
    // } else {
    //     openings_hours= <p style={{color : "#00a591"}}>{props.openings_hours}<ion-icon name="information-circle"></ion-icon></p>
    // }

    // var now = new Date();
    // var weekday = new Array(7);
    // weekday[0] = "Sunday";
    // weekday[1] = "Monday";
    // weekday[2] = "Tuesday";
    // weekday[3] = "Wednesday";
    // weekday[4] = "Thursday";
    // weekday[5] = "Friday";
    // weekday[6] = "Saturday";
    // var today = weekday[now.getDay()];
    // var dayOfWeek = now.getDay();
    // var hour = now.getHours();
    // var minutes = now.getMinutes();

    // console.log(today , dayOfWeek , hour , minutes);
    
    // var checkTime = function() {
    //   var today = weekday[now.getDay()];
    //   var dayOfWeek = now.getDay();
    //   var hour = now.getHours();
    //   var minutes = now.getMinutes();
    
    //   //add AM or PM
    //   var suffix = hour >= 12 ? "PM" : "AM";
    
    //   // add 0 to one digit minutes
    //   if (minutes < 10) {
    //     minutes = "0" + minutes
    //   };
    
    //   if ((dayOfWeek == 0 || dayOfWeek == 6) && hour >= 13 && hour <= 23) {
    //     hour = ((hour + 11) % 12 + 1); //i.e. show 1:15 instead of 13:15
    //     timeDiv.innerHTML = 'it\'s ' + today + ' ' + hour + ':' + minutes + suffix + ' - we\'re open!';
    //     timeDiv.className = 'open';
    //   } 
      
    //   else if ((dayOfWeek == 3 || dayOfWeek == 4 || dayOfWeek == 5) && hour >= 16 && hour <= 23) {
    //     hour = ((hour + 11) % 12 + 1);
    //     timeDiv.innerHTML = 'it\'s ' + today + ' ' + hour + ':' + minutes + suffix + ' - we\'re open!';
    //     timeDiv.className = 'open';
    //   } 
      
    //   else {
    //     if (hour == 0 || hour > 12) {
    //       hour = ((hour + 11) % 12 + 1); //i.e. show 1:15 instead of 13:15
    //     }
    //     timeDiv.innerHTML = 'It\'s ' + today + ' ' + hour + ':' + minutes + suffix + ' - we\'re closed!';
    //     timeDiv.className = 'closed';
    //   }
    // };

    // setInterval(checkTime, 1000);
    // checkTime();

    // console.log(checkTime);
    let distance = getDistanceFromLatLonInKm(props.origin[0], props.origin[1], props.destination[0], props.destination[1])
    // console.log(props.stars)
    let img_src= '/img/logo_gears.jpg';

    // if (props.logo) {img_src = `https://boiling-stream-41133.herokuapp.com/${props.logo}`}; 
    if (props.logo) {img_src = `https://api.findfix.gr:4000/${props.logo}`}; 

    let stars, discount = null;
    if(props.stars) {
        stars = (
        <div className="starratingscss">
            {/* πάει το data.stars επι το font size */}
            <div className="starratingscsstop" style={{width : props.stars*13}}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
            <div className="starratingscssbottom"><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span><span>({props.total})</span></div>
        </div>
        );
    } else {
        stars = (
            <div className="starratingscss">
                {/* πάει το data.stars επι το font size */}
                <div className="starratingscsstop" style={{width : '0px'}}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                <div className="starratingscssbottom"><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span><span>({props.total})</span></div>
            </div>
            );
    }

    if(props.discount) {
        discount = <div style={{color : '#EF4657'}}>έκπτωση έως {props.discount}%</div>
    }

    return (
    
    <Link to={`/${props.locality}/${props.route}/${props.name}`}>
        <div className="result">
        <div className="result-body">
                <div className="image"  style={{backgroundImage : `url(${img_src})`, backgroundRepeat : 'no-repeat'}}>
                </div>
            <h2 className="result-title">{props.name}</h2>
            <div className="result-text">{props.route} {props.street_number} {props.locality}</div>
         {/* <div> {stars} </div> */} 
         <div className="result-text">{discount}</div>
        </div> 
         
        <div className="result-footer">
            <div className="d-flex justify-content-between">
                <p><ion-icon name="pin"></ion-icon>{distance} Km</p>
                {/* <div className="dot" style={dot}> */}
                {/* {dot} */}
                {stars}
               
                {/* </div> */}
            </div>
        </div>
    </div>
    </Link>
    );
}


export default shop;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {categoryFilter , categoryFilterToShops, brandFilter, issueFilter, modelFilter } from '../../Store/actions';
import Select2 from 'react-select2-wrapper';
import './Filters.css';
import 'react-select2-wrapper/css/select2.css';

import { Category } from '../../Config/Category';
import { Brands } from '../../Config/Brands';
import { Models } from '../../Config/Models';
import { Issues } from '../../Config/Issues';

class Filters extends Component {


  render () {

// create brand filter
let brandFilterEllement = null;
let brandFilterData = Brands[this.props.ctgrFilter];
// console.log(brandFilterData);
if (brandFilterData) { 
  brandFilterEllement =  
    <div className="form-group col-md-3">
      <label htmlFor="brand">Μάρκα συσκευής;</label>
      <Select2
        value ={this.props.brndFilter}
        data={brandFilterData}
        onChange={this.props.onBrandFilter}
        className="form-control" 
        id="brand"
    /> 
    </div>
  }


//create model filter
let modelFilterEllement = null;
let modelFilterData = Models[this.props.brndFilter];
if (modelFilterData) { 
  modelFilterEllement =  
    <div className="form-group  col-md-3">
      <label htmlFor="model">Μοντέλο</label>
      <Select2
        value ={this.props.mdlFilter}
        data={modelFilterData}
        onChange={this.props.onModelFilter}
        className="form-control" 
        id="model"
    /> 
    </div>
}

//create issue filter
let issueFilterEllement = null;
let issueFilterData = Issues[this.props.ctgrFilter];
if ((issueFilterData && this.props.brndFilter !==null && this.props.brndFilter !=="") || this.props.issFilter) { 
  issueFilterEllement =  
    <div className="form-group col-md-3" >
      <label htmlFor="issue">Πρόβλημα</label>
      <Select2
        value ={this.props.issFilter}
        data={issueFilterData}
        onChange={this.props.onIssueFilter}
        className="form-control" 
        id="issue"
    /> 
    </div>
}

    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="category">Τι θέλεις να επισκευάσεις;</label>
            <Select2
              value ={this.props.ctgrFilter}
              data={Category}
              onChange={(event, shop) => {this.props.onCategoryToShop(event, this.props.shp)}}
              className="form-control" 
              id="category"
        /> 
          </div>
          {brandFilterEllement}
          {modelFilterEllement}
          {issueFilterEllement}
        </div>
      </div>
    );
}}


const mapStateToProps = state => {
  return {
    ctgrFilter : state.filterReducer.category,
    brndFilter : state.filterReducer.brand,
    mdlFilter : state.filterReducer.model,
    issFilter : state.filterReducer.issue,
    shp : state.shopsReducer.shops,
  }; 
};

const mapDispatchToProps = dispatch => {

  return {
    onCategoryFilter: (event) => dispatch(categoryFilter(event.target.value)),
    onBrandFilter: (event) => dispatch(brandFilter(event.target.value)),
    onModelFilter: (event) => dispatch(modelFilter(event.target.value)),
    onIssueFilter: (event) => dispatch(issueFilter(event.target.value)),
    onCategoryToShop : (event , shop) => dispatch(categoryFilterToShops(event.target.value, shop)),



  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
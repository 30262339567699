import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select2 from 'react-select2-wrapper';

import { descriptionServices } from '../../Store/actions';

import './Filters.css';
import 'react-select2-wrapper/css/select2.css';


class Filters extends Component {

  render () {
    return (
      <div className="form-row">
         <div className="form-group col-10">
            <Select2
              value ={this.props.serWay}
              data={this.props.descriptionServicesData}
              onChange={this.props.onDescriptionServiceFilter}
              className="form-control modalfilter" 
              id="descriptionServices"
        /> 
        </div>
        <div className="form-group col-2">
          <span onClick={()=>{this.props.deleteDescriptionServiceFilter(null)}}>
            <ion-icon name="close-circle-outline"></ion-icon>
          </span>
        </div>
      </div>
    );
}}


const mapStateToProps = state => {
  return {
    serWay : state.filterReducer.serviceWay,
    show : state.uiReducer.show,
    shpId : state.uiReducer.shopId
  }; 
};

const mapDispatchToProps = dispatch => {

  return {
    onDescriptionServiceFilter: (event) => dispatch(descriptionServices(event.target.value)),
    deleteDescriptionServiceFilter: (val) => dispatch(descriptionServices(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

import * as actionTypes from '../actions/actionsTypes';
import { Cookies } from 'react-cookie';


//δημιουργία cookie
const cookies = new Cookies();


const initialState = {
    address : '',
    geometry : null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
         case actionTypes.IN_CHANGE:
            // cookies.set('addr', action.value, {secure : true , sameSite : true});
            cookies.set('addr', action.value);
            return {
                ...state,
                address : action.value
            }
        case actionTypes.GEOMETRY_CHANGE:
            return {
                ...state,
                geometry : action.value
            }
        default :
            return state;
    }
}

export default reducer;
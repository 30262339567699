import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { popularButton } from '../../Store/actions';
import styles from './Popular.module.css'

class Popular extends Component {


  
    render() {
    return (
        <div className={`container ${styles.popular}`}>
        <h3>Δημοφιλείς αναζητήσεις</h3>
        <div className="divider"></div>
            <div className="row">
                <button type="button" className="btn btn-outline-secondary popular" name="laptop/brand/model/Αλλαγή πληκτρολογίου" onClick={this.props.clickHandler} ><ion-icon name="search" ></ion-icon>Αλλαγή πληκτρολογίου σε laptop</button>
                <button type="button" className="btn btn-outline-secondary popular" name="phone/Apple/iPhone 6/Αλλαγή μπαταρίας" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Αλλαγή μπαταρία iPhone 6s</button>
                <button type="button" className="btn btn-outline-secondary popular" name="desktop/brand/model/Αλλαγή Τροφοδοτικού" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Αλλαγή τροφοδοτικού σε σταθερό Η/Υ</button>
                <button type="button" className="btn btn-outline-secondary popular" name="laptop/brand/model/Επισκευή κάρτας γραφικών" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Δεν δείχνει το laptop</button>
                <button type="button" className="btn btn-outline-secondary popular" name="phone/Samsung/SM-J530F/Σπασμένη οθόνη LCD" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Αλλαγή οθόνης σε samsung j5 2017</button>
                <button type="button" className="btn btn-outline-secondary popular" name="printer/brand/model/Τυπώνει λευκές σελίδες" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Ο εκτυπωτής τυπώνει λευκές σελίδες</button>
                <button type="button" className="btn btn-outline-secondary popular" name="tablet/Apple/iPad/Σπασμένη οθόνη αφής" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Σπασμένη οθόνη αφής σε iPad</button>
                <button type="button" className="btn btn-outline-secondary popular" name="laptop/brand/model/Αλλαγή σκληρού δίσκου" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Αναβάθμιση laptop με δίσκο SSD</button>
                <button type="button" className="btn btn-outline-secondary popular" name="phone/Apple/iPhone 7/Βλάβη από νερό" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>iPhone 7 έπεσε σε νέρο</button>
                <button type="button" className="btn btn-outline-secondary popular" name="laptop/brand/model/Βλάβη από νερό" onClick={this.props.clickHandler}><ion-icon name="search"></ion-icon>Έπεσε καφές στο laptop</button>
            </div>
        </div>
    )
    }
}


const mapStateToProps = state => {
    return {
      
    };
};

const mapDispatchToProps = dispatch => {

    return {
        clickHandler: (event) => {dispatch(popularButton(event.target.name))},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Popular));


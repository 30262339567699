// export const Brands = {
//     'Κινητό' :['Huawei','Samsung','Apple', 'Sony', 'Nokia', 'Xiaomi' , 'Asus', 'Cubot', 'HTC', 'Lenovo' ,'LG', 'MLS','ZTE'],
//     'Laptop' : [ 'Acer', 'Apple', 'Asus', 'Dell','HP', 'Lenovo', 'MSI' , 'Samsung', 'Toshiba', 'ΤurboX'],
//     'Σταθερός Η/Υ' : [ 'Apple' ,'Dell' , 'HP' , 'Lenovo' ,'Asus' , 'MSI' , 'Fujitsu', 'Acer', 'TurboX', 'Quest', 'Άλλο'],
//     'Tablet' : ['Acer','Apple','Archos','Asus','Bitmore','eStar','HP','Huawei','Lenovo','Microsoft','MLS','Samsung'],
//     'Εκτυπωτής' : ['Canon','Epson','HP','Kyocera','Lexmark','Ricoh','Samsung','Xerox'] , 
//     'Game Console' : ['Microsoft','Nintendo','Sony']

// };

export const Brands = {
    'Κινητό' :['Apple','Xiaomi','Samsung','Sharp','Sony','TP-LINK','Turbo-X','Ulefone','Google','HTC','Lenovo','MLS','Hisense','Huawei','Archos','Asus','Coolpad','Alcatel','Cubot','Doogee','Elephone','LG','Nokia','OnePlus','Vodafone','Άλλο'],
     'Laptop':['Acer','Apple','Asus','Dell','Fujitsu','Google','HP','Huawei','Lenovo','MSI','Microsoft','Razer','Toshiba','Turbo-X','Vero','Xiaomi','Άλλο'],
    'Σταθερός Η/Υ':[ 'Acer','Apple','Asus','Dell','Fujitsu','HP','Lenovo','MSI','Turbo-X','Quest','Άλλο'],
    'Tablet':['Huawei','Lenovo','Acer','Alcatel','Apple','Archos','Asus','Bitmore','Crypto','Dell','eStar','Fluo','HP','Linx','MLS','Microsoft','Quest','Samsung','Turbo-X','Άλλο'],
    'Εκτυπωτής':['Brother','Canon','DNP','Epson','Fujifilm','HITI','HP','Kodak','Konica Minolta','Kyocera','Lexmark','Mitsubishi','OKI','Panasonic','Pantum','PhotoBee','Polaroid','Ricoh','Riso','Samsung','Tomy','Xerox','Άλλο'] , 
    'Game Console' :['Microsoft','Nintendo','Sony','Sega','Άλλο']
};

import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    user : {},
    address : {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.USER_DATA:
            return {
                ...state,
                user : action.data
                     }
        case actionTypes.USER_ADDRESS:
        return {
            ...state,
            address : action.data
                }
        default :
            return state;
    }
}

export default reducer;
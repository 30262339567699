import React, { Component } from 'react';
import { withGoogleMap, GoogleMap , OverlayView} from 'react-google-maps';

import './SingleShopMap.css';
// import shop from '../../Components/Shop/Shop';

class Map extends Component {
   render() {



    let current = { lat: 38.031200, lng: 23.706840}
    if (this.props.current) { current =  {lng : this.props.markers[0], lat: this.props.markers[1]} }
    const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })
  
   const GoogleMapExample = withGoogleMap(props => (
  
      <GoogleMap
        defaultCenter = { current }
        defaultZoom = { 13 }
      >

     <OverlayView
      position={ current }
      /*
       * An alternative to specifying position is specifying bounds.
       * bounds can either be an instance of google.maps.LatLngBounds
       * or an object in the following format:
       * bounds={{
       *    ne: { lat: 62.400471, lng: -150.005608 },
       *    sw: { lat: 62.281819, lng: -150.287132 }
       * }}
       */
      /*
       * 1. Specify the pane the OverlayView will be rendered to. For
       *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
       *    Defaults to `OverlayView.OVERLAY_LAYER`.
       */
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      /*
       * 2. Tweak the OverlayView's pixel position. In this case, we're
       *    centering the content.
       */
      getPixelPositionOffset={getPixelPositionOffset}
      /*
       * 3. Create OverlayView content using standard React components.
       */
    >
      <span className="pin"></span>

    </OverlayView>

      </GoogleMap>
   ));
   return(
    <div>
        <GoogleMapExample
          containerElement={ <div style={{height:'300px', width: '100%', display : 'flex'}} /> }
          mapElement={ <div style={{ height: `100%` , width : `100%`}} /> }
            current = {current}
        />
      
    </div>
   );
   }
};
export default Map;
import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    category : '',
    brand : '',
    model : '',
    issue : '',
    comment : '',
    result : null,
    serviceWay : ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.CATEGORY_FILTER:
            return {
                ...state,
                category : action.value,
            }
        case actionTypes.BRAND_FILTER:
        return {
            ...state,
            brand : action.value
        }
        case actionTypes.MODEL_FILTER:
        return {
            ...state,
            model : action.value
        }
        case actionTypes.ISSUE_FILTER:
        return {
            ...state,
            issue : action.value
        }
        case actionTypes.COMMENT_FILTER:
        return {
            ...state,
            comment : action.value
        }
        case actionTypes.SEARCH_FILTER:
        return {
            ...state,
            result : action.value
        }
        case actionTypes.DESCRIPTION_SERVICE_FILTER:
        return {
            ...state,
            serviceWay : action.value
        }
        default :
            return state;
    }
}

export default reducer;
import * as actionsTypes from './actionsTypes';
import axios from '../../axios';
import {modalShow} from './index';

export const favPartner = () => {
    return {
        type : actionsTypes.FAVORITE_PARTNER
        };
}

export const unFavPartner = () => {
    return {
        type : actionsTypes.UN_FAVORITE_PARTNER
        };
}

export const favPartnerHandler =(value) => {
   
    return dispatch => {
        if (localStorage.getItem('userId')) {
            let data = { userId : localStorage.getItem('userId')}
            axios.post(`/user/faved/${value}`, data)
            .then(response => {
                console.log('faved');
                dispatch(favPartner());
            })
            .catch(error => {
                alert(error.response.data.message)
            }
            ) } else {
                dispatch(modalShow('ΣΥΝΔΕΣΗ', null))
            }
    };
}


export const issueEmailConfirmation =(value) => {
   
    return dispatch => {
       axios.get(`/user/enquirys/email/${sessionStorage.getItem('userId')}`)
       .then(response => {
           console.log('email send');
       })
       .catch()
    };
}


export const issueEmailMessageConfirmation =(user, shop, pass) => {
   
    return dispatch => {
       axios.get(`/user/enquirys/message/${user}/${shop}/${pass}`)
       .then(response => {
           console.log(response);
       })
       .catch()
    };
}



